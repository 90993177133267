import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { toServerTime } from "../../../../Services/Timezone";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { getAllUsers } from "../../../../Services/api/Roles/RolesProvider";
import { addCoupon, listTraineesCoupon } from "../../../../Services/api/coupon/couponProvider";
import { fetchAllProgram } from "../../../../Services/api/program/programContext";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import { Hint } from "./../../../../Components";
import { decrypt } from "../../../../Helpers/Secret";

function AddCoupon(props) {
  require("./addCoupon.css");
  const { t } = useTranslation();
  const history = useHistory();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);
  const [hoursDatepickerFir, setHoursDatepickerFir] = useState(null);
  const [minutesDatepickerFir, setMinutesDatepickerFir] = useState(null);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [isTraineesLoading, setIsTraineesLoading] = useState(true);

  function SearchUser({ values, setFieldValue, initialUsers = [] }) {
    return (
      <div className="tw-border tw-rounded tw-divide-y">
        <div className="tw-bg-gray-100 tw-p-4 md:tw-flex tw-items-center tw-justify-between">
          <div className="tw-font-semibold tw-text-gray-500">{t("select_name")}</div>
          <div className="tw-flex tw-items-center tw-space-s-2">
            <Field
              name={`users-search`}
              className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent search-field"
              placeholder={t("search_by_name_or_email")}
              onChange={({ target: { value } }) => {
                // setFieldValue(`users.${index}.search`, value);
                // fetchUsers(value, values.users[index].user?.id);
              }}
            />
            <i className="fal fa-search tw-text-teal-500 tw-w-6"></i>
          </div>
        </div>
        <div className="tw-py-4 tw-pe-4">
          <div className="tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-max-h-[40vh] tw-pe-4">
            {isTraineesLoading ? (
              [...Array(5).keys()].map((i) => (
                <div
                  key={i}
                  className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
                >
                  <div className="tw-flex tw-items-center tw-space-s-4">
                    <div className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded tw-border-gray-300`}></div>
                    <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
                  </div>
                  <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
                </div>
              ))
            ) : trainees.length ? (
              trainees?.map((user) => (
                <button
                  key={user?.id}
                  type="button"
                  onClick={() => {
                    if (values?.users?.includes(user?.id)) {
                      setFieldValue(
                        `users`,
                        values.users.filter((id) => id !== user?.id)
                      );
                    } else {
                      setFieldValue(`users`, [...values.users, user?.id]);
                    }
                  }}
                  className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-4 tw-w-full`}
                >
                  <div className="tw-flex tw-items-center tw-space-s-4">
                    <div
                      className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded  ${
                        values?.users?.includes(user?.id)
                          ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                          : "tw-border-gray-300"
                      }`}
                    ></div>
                    <div>{i18n.language === Lang.AR ? user?.name_ar : user?.name_en}</div>
                  </div>
                  <div>{user?.email}</div>
                </button>
              ))
            ) : (
              <div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">{t("there_no_matching_users")}</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours == "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
    },
  ];

  const getAllCoursesHandler = async () => {
    getAllCourses({
      mode: "Paid",
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCourses(res?.data?.data?.courses?.data || []);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      });
  };

  const getAllProgramsHandler = async () => {
    fetchAllProgram()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setPrograms(res?.data?.data?.programs);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      });
  };

  const getAllTraineesHandler = async () => {
    setIsTraineesLoading(true);
    listTraineesCoupon()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTrainees(res?.data?.response);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      })
      .finally(() => {
        setIsTraineesLoading(false);
      });
  };

  useEffect(() => {
    getAllCoursesHandler();
    getAllProgramsHandler();
    getAllTraineesHandler();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="admin_label">
              <NavLink to={`${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}`}>
                {t("admin.label.admin_label")}
              </NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              <NavLink to={`${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/coupon`}>
                {t("coupon.label.coupon")}
              </NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("coupon.label.create_coupon")}
            </div>

            {isLoading ? (
              <div
                style={{
                  height: "65vh",
                }}
              >
                <SkeletonCard />
              </div>
            ) : (
              <div className="admin_box_bg">
                <div className="admin_label_card">
                  <div className="">{t("coupon.label.label_name")}</div>
                </div>
                <div className="admin_input_coupon">
                  <Formik
                    initialValues={{
                      name: "",
                      value: "",
                      mechanism: "1",
                      start_date: "",
                      end_date: "",
                      parentType: 1,
                      parent_id: "",
                      users: [],
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        //
                        let dataToBeUploaded = {
                          ...values,
                          times_trainee: values.users.length,
                        };
                        dataToBeUploaded.start_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.start_day)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.start_time))
                        );

                        dataToBeUploaded.end_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.end_day)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.end_time))
                        );

                        delete dataToBeUploaded.start_day;
                        delete dataToBeUploaded.start_time;
                        delete dataToBeUploaded.end_day;
                        delete dataToBeUploaded.end_time;

                        let response = await addCoupon(dataToBeUploaded);
                        if (response.status === 201 && response.data.status) {
                          setIsLoading(true);
                          history.push(
                            `${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/coupon`
                          );
                          setIsLoading(false);
                        } else {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {response.data.message ? response.data.message : "Failure in service"}
                            </span>
                          );
                          setSubmitting(false);
                        }
                      } catch (err) {
                        toast.error(
                          <div>
                            {Object.keys(err.response.data.errors).map((key) => {
                              return <span style={{ fontSize: 12 }}>{err.response.data.errors[key]}</span>;
                            })}
                          </div>
                        );
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }

                      if (!values.start_day) {
                        errors.start_day = t("crud.errors.required");
                      }

                      if (!values.end_day) {
                        errors.end_day = t("crud.errors.required");
                      }

                      if (!values.start_time) {
                        errors.start_date = t("crud.errors.required");
                      }

                      if (!values.end_time) {
                        errors.end_date = t("crud.errors.required");
                      }

                      if (!values.value) {
                        errors.value = t("crud.errors.required");
                      }

                      if (values.users.length == 0) {
                        errors.users = t("crud.errors.required");
                      }
                      if (!values.parentType) {
                        errors.parentType = t("crud.errors.required");
                      }
                      if (!values.parent_id) {
                        errors.parent_id = t("crud.errors.required");
                      }

                      return errors;
                    }}
                  >
                    {({ values, errors, handleChange, handleSubmit, isSubmitting, handleBlur, setFieldValue }) => (
                      <form onSubmit={handleSubmit} className="admin_add_form">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="name" className="admin_add_label">
                              {t("coupon.label.coupon_name")}
                            </label>
                            <input
                              id="name"
                              type="text"
                              name="name"
                              className="admin_add_input"
                              onChange={handleChange}
                              placeholder={t("coupon.placeholders.coupon_name")}
                              value={values.name}
                            />
                            <Hint hint={t("hint.admin.coupon.name")} color="#006d77" fontSize={12} />
                            <p className={"form-input-error-space"}>{errors.name ? errors.name : null}</p>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="mechanism" className="admin_add_label">
                              {t("coupon.label.machinsim")}
                            </label>
                            <div className="row">
                              <div className="col-md-6">
                                <select
                                  name=""
                                  id="mechanism"
                                  className="admin_add_input"
                                  value={values.mechanism}
                                  onChange={handleChange}
                                >
                                  <option value="2">{t("coupon.label.per_value")}</option>
                                  <option value="1">{t("coupon.label.co_value")}</option>
                                </select>
                              </div>
                              <div className="col-md-6">
                                {values.mechanism == "2" ? (
                                  <input
                                    id="value"
                                    type="number"
                                    min="0"
                                    max="100"
                                    name="value"
                                    className="admin_add_input"
                                    onChange={handleChange}
                                    placeholder={t("coupon.placeholders.value_coupon")}
                                    value={values.value}
                                    step="0.01"
                                  />
                                ) : (
                                  <input
                                    id="value"
                                    type="number"
                                    min="0"
                                    name="value"
                                    className="admin_add_input"
                                    onChange={handleChange}
                                    placeholder={t("coupon.placeholders.value_coupon")}
                                    value={values.value}
                                    step="0.01"
                                  />
                                )}
                                <p className={"form-input-error-space"}>{errors.value ? errors.value : null}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="course_program" className="admin_add_label">
                              {t("coupon.label.select_course_or_program")}
                            </label>
                            <Select
                              name="course_program"
                              id="course_program"
                              // isClearable={true}
                              options={parentTypeOptions}
                              value={parentTypeOptions.find((option) => option.id == values.parentType)}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.id}
                              onChange={(item) => {
                                setFieldValue("parentType", item.id);
                                setFieldValue("parent_id", "");
                              }}
                              placeholder={t("coupon.label.select_course_or_program")}
                            />
                            <p className={"form-input-error-space"}>{errors.parentType ? errors.parentType : null}</p>
                          </div>
                          {values.parentType == 1 && (
                            <div className="col-md-6">
                              <label htmlFor="courses" className="admin_add_label">
                                {t("coupon.label.select_course")}
                              </label>
                              <Select
                                name="courses"
                                id="courses"
                                options={courses}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(item) => {
                                  setFieldValue("parent_id", item.id);
                                }}
                                placeholder={t("coupon.label.select_course")}
                              />
                              <p className={"form-input-error-space"}>{errors.parent_id ? errors.parent_id : null}</p>
                            </div>
                          )}
                          {values.parentType == 2 && (
                            <div className="col-md-6">
                              <label htmlFor="programs" className="admin_add_label">
                                {t("coupon.label.select_program")}
                              </label>
                              <Select
                                name="programs"
                                id="programs"
                                options={programs}
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.id}
                                onChange={(item) => {
                                  setFieldValue("parent_id", item.id);
                                }}
                                placeholder={t("coupon.label.select_program")}
                              />
                              <p className={"form-input-error-space"}>{errors.parent_id ? errors.parent_id : null}</p>
                            </div>
                          )}

                          <div className="col-md-6">
                            <label htmlFor="start_day" className="admin_add_label">
                              {t("coupon.label.start_day")}
                            </label>
                            <DatePicker
                              selected={values.start_day}
                              onChange={(date) => {
                                setFieldValue("start_day", date);
                                let DateNow = new Date().getDate();
                                let HoursNow = new Date().getHours();

                                if (date.getDate() == DateNow) {
                                  setHoursDatepickerFir(HoursNow);
                                } else {
                                  setHoursDatepickerFir(0);
                                }
                              }}
                              className="admin_add_input"
                              dateFormat={"yyyy-MM-dd"}
                              minDate={new Date()}
                              placeholderText={t("coupon.placeholders.start_day")}
                            />

                            <p className={"form-input-error-space"}>{errors.start_day ? errors.start_day : null}</p>
                          </div>

                          <div className="col-md-6 time_use">
                            <label htmlFor="start_time" className="admin_add_label">
                              {t("coupon.label.start_time")}
                            </label>
                            <DatePicker
                              selected={values.start_time}
                              onChange={(date) => setFieldValue("start_time", date)}
                              showTimeSelect
                              placeholderText={t("coupon.placeholders.start_time")}
                              className="admin_add_input"
                              showTime={{ use12Hours: false }}
                              dateFormat={"hh:mm aa"}
                              minTime={setHours(setMinutes(new Date(), 0), hoursDatepickerFir)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                            />
                            <p className={"form-input-error-space"}>{errors.start_time ? errors.start_time : null}</p>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="end_day" className="admin_add_label">
                              {t("coupon.label.end_day")}
                            </label>
                            <DatePicker
                              selected={values.end_day}
                              onChange={(date) => {
                                setFieldValue("end_day", date);
                                let StartDay = values.start_day;
                                let endDay = date;

                                if (StartDay.getDay() == endDay.getDay()) {
                                  setHoursDatepicker(values.start_time.getHours());
                                  if (values.start_time.getMinutes() == 30) {
                                    setHoursDatepicker(values.start_time.getHours() + 1);
                                    setMinutesDatepicker(0);
                                  } else {
                                    setHoursDatepicker(values.start_time.getHours());
                                    setMinutesDatepicker(values.start_time.getMinutes() + 30);
                                  }
                                } else {
                                  setHoursDatepicker(0);
                                  setMinutesDatepicker(0);
                                }
                              }}
                              className="admin_add_input"
                              disabled={values.start_time && values.start_day ? false : true}
                              minDate={new Date()}
                              dateFormat={"yyyy-MM-dd"}
                              placeholderText={t("coupon.placeholders.end_day")}
                            />

                            <p className={"form-input-error-space"}>{errors.end_day ? errors.end_day : null}</p>
                          </div>

                          <div className="col-md-6 time_use">
                            <label htmlFor="end_time" className="admin_add_label">
                              {t("coupon.label.end_time")}
                            </label>
                            <DatePicker
                              selected={values.end_time}
                              onChange={(date) => {
                                setFieldValue("end_time", date);
                              }}
                              showTimeSelect
                              placeholderText={t("coupon.placeholders.end_time")}
                              className="admin_add_input"
                              showTime={{ use12Hours: false }}
                              disabled={values.start_time && values.end_day ? false : true}
                              minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              dateFormat={"hh:mm aa"}
                            />
                            <p className={"form-input-error-space"}>{errors.end_time ? errors.end_time : null}</p>
                          </div>

                          <div className="col-md-12">
                            <label htmlFor="end_time" className="admin_add_label">
                              {t("coupon.label.direct_to")}
                            </label>
                            <SearchUser
                              values={values}
                              setFieldValue={setFieldValue}
                              // initialUsers={users}
                            />
                            <p className={"form-input-error-space"}>{errors.users ? errors.users : null}</p>
                          </div>

                          <div className="col-12 mt-4 tw-flex tw-justify-end">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
                            >
                              {t("coupon.values.add")}
                            </button>
                          </div>
                          {/* <div className="col-md-2">
                        <NavLink to="/admin/coupon">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="admin_edit_button"
                          >
                            {t("coupon.values.back")}
                          </button>
                        </NavLink>
                      </div> */}
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCoupon;
