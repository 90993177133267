import { Formik } from "formik";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";

import { addPaymentRequest, getCoursesForUser } from "../../../../../Services/api/Financial/FinancialProvider";
import HomeIcon from "../../../../../assets/icons/home.svg";

import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../../Shared/Components/DataTable/DataTable";

import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";

import { decrypt } from "../../../../../Helpers/Secret";
import AddPaymentAction from "./AddPaymentAction";
import classes from "./addPaymentRequest.module.css";

function AddPeymentRequest({ role }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  //   const [trainees, setTrainees] = useState([]);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [programsIn, setProgramsIn] = useState([]);

  //   const [isTraineesLoading, setIsTraineesLoading] = useState(true);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `${role === "admin" ? "content_development" : "private_profile"}`,
      page: t("sidebar.label.private_profile"),
      pagePath: "/private-profile",
    },
    {
      id: `peyment_request`,
      page: t("private_profile.financial_request"),
      pagePath: `${
        role === "manager" ? "/manager" : role === "trainer" ? "/trainer" : "private-profile"
      }/payments-requests`,
    },
    {
      id: `peyment_request`,
      page: t("private_profile.add_financial_request"),
      pagePath: "#",
    },
  ];

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
    },
    ...(role === "manager"
      ? [
          {
            label: t("coupon.label.program"),
            id: 2,
          },
        ]
      : []),
    ...(role === "trainer"
      ? [
          {
            label: t("private_profile.course_in_program"),
            id: 3,
          },
        ]
      : []),
  ];

  const getCoursesListHandler = async ({ inProgram = false, isProgram = false }) => {
    getCoursesForUser(role === "manager" ? "manager" : "trainer", {
      page: 1,
      perPage: 10000,
      program_id: null,
      status: "Published",

      ...(inProgram ? { not_in_program: 0 } : { not_in_program: 1 }),
      ...(isProgram ? { is_program: 1 } : { is_program: 0 }),
    })
      .then((response) => {
        if (response.status && response.status == 200 && response.data.status) {
          if (isProgram) {
            setPrograms(response?.data?.data?.courses?.data || []);
          } else if (inProgram) {
            setProgramsIn(response?.data?.data?.courses?.data || []);
          } else {
            setCourses(response?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      })
      .finally(() => {
        setIsCoursesLoading(false);
        setIsProgramsLoading(false);
      });
  };

  useEffect(() => {
    getCoursesListHandler({ inProgram: false, isProgram: false });
    getCoursesListHandler({ inProgram: true, isProgram: false });
    getCoursesListHandler({ inProgram: false, isProgram: true });

    setIsLoading(false);
  }, []);

  const coursesFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
    {
      id: "code",
      column: ({ rowData }) => <>{rowData.code || "-"}</>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <>{rowData.run_serial_number || "-"}</>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => <>{rowData.start_date || "-"}</>,
    },
  ];

  return (
    <>
      <div className={classes["create_tax"]}>
        <div className="container-fluid">
          <div className={classes["create_tax__container"]}>
            <div className="row">
              <div className="col-12">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            {!isLoading && (
              <div className="row">
                <div className="col-12">
                  <MainBox>
                    <Formik
                      initialValues={{
                        note: "",
                        parentType: 1,
                        parent_id: "",
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        try {
                          let dataToBeUploaded = {
                            note: values.note,
                            course_id: values.parent_id,
                          };

                          let response = await addPaymentRequest(dataToBeUploaded);
                          if (response.status === 201 && response.data.status) {
                            setIsLoading(true);
                            history.push(
                              `${
                                role === "manager" ? "/manager" : role === "trainer" ? "/trainer" : "private-profile"
                              }/payments-requests`
                            );
                            setIsLoading(false);
                          } else {
                            toast.error(
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                {response.data.message ? response.data.message : "Failure in service"}
                              </span>
                            );
                            setSubmitting(false);
                          }
                        } catch (err) {
                          // setSubmitting(false);
                          console.log(err);
                          toast.error(<span style={{ fontSize: 12 }}>{err.response.data.msg}</span>);
                        }
                      }}
                      validateOnChange={hasSubmitted}
                      validate={(values) => {
                        setHasSubmitted(true);

                        const errors = {};
                        if (!values.note) {
                          errors.note = t("crud.errors.required");
                        }
                        if (!values.parentType) {
                          errors.parentType = t("crud.errors.required");
                        }
                        if (!values.parent_id) {
                          errors.parent_id = t("crud.errors.required");
                        }

                        return errors;
                      }}
                    >
                      {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        handleBlur,
                        setFieldValue,
                        validateForm,
                      }) => (
                        <form onSubmit={handleSubmit} className={classes["create_tax__form"]}>
                          <MainBoxHead title={t("private_profile.add_financial_request")} />
                          <div className={`row ${classes["create_tax__form__fields"]}`}>
                            <div className={`${classes["create_tax__form__field"]} col-12`}>
                              <label htmlFor="course_program">{t("coupon.label.select_course_or_program")}</label>
                              <Select
                                name="course_program"
                                id="course_program"
                                options={parentTypeOptions}
                                value={parentTypeOptions.find((option) => option.id == values.parentType)}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.id}
                                onChange={(item) => {
                                  setFieldValue("parentType", item.id);
                                  setFieldValue("parent_id", "");
                                }}
                                placeholder={t("coupon.label.select_course_or_program")}
                              />
                              <p className={"form-input-error-space"}>{errors.parentType ? errors.parentType : null}</p>
                            </div>
                            <div className={`${classes["create_tax__form__field"]} col-12`}>
                              <label htmlFor="courses_programs">
                                {values.parentType == 1 || values.parentType == 3
                                  ? t("coupon.label.select_course")
                                  : t("coupon.label.select_program")}
                              </label>
                              <DataTable
                                title={
                                  values.parentType == 1 || values.parentType == 3
                                    ? t("coupon.label.select_course")
                                    : t("coupon.label.select_program")
                                }
                                selectedItems={[values.parent_id]}
                                isLoading={values.parentType == 1 ? isCoursesLoading : isProgramsLoading}
                                data={values.parentType == 1 ? courses : values.parentType == 2 ? programs : programsIn}
                                fields={coursesFields}
                                isMultiple={false}
                                searchableFields={["id", "name", "code", "run_serial_number"]}
                                selectedHandler={(item) => {
                                  setFieldValue("parent_id", item?.id);
                                }}
                              />
                              <p className={"form-input-error-space"}>{errors.parent_id ? errors.parent_id : null}</p>
                            </div>
                            <div className={`${classes["m-40"]} col-12`}>
                              <div className={classes["d-flex-input"]}>
                                <label className={classes["input-label"]}>
                                  {t("financial.payments_request.notes")}
                                </label>
                              </div>

                              <textarea
                                name="note"
                                value={values.note}
                                onChange={(e) => setFieldValue("note", e.target.value)}
                                rows={5}
                                cols={30}
                                maxLength={3000}
                                className={classes.textarea}
                              />
                              {errors.note && (
                                <p className={`form-input-error-space ${classes["input-field-error"]}`}>
                                  {errors.note}
                                </p>
                              )}
                            </div>
                            <AddPaymentAction submitAction={handleSubmit} isSubmitting={isSubmitting} />
                          </div>
                        </form>
                      )}
                    </Formik>
                  </MainBox>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPeymentRequest;
