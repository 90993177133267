import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "../../../../i18n/i18n";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import classes from "./walletList.module.css";

const WalletListTable = () => {
  const { walletTransactions, isLoading } = useSelector((state) => state.userWalletReducer);

  const { t } = useTranslation();

  const displayStatus = {
    Waiting: <span className={classes["waiting"]}>{t("general.waiting")}</span>,
    Approved: <span className={classes["approved"]}>{t("general.approved")}</span>,
    Rejected: <span className={classes["rejected"]}>{t("general.rejected")}</span>,
    Canceled: <span className={classes["canceled"]}>{t("general.canceled")}</span>,
  };
  const displayGift = (is_gift) => {
    if (is_gift) {
      return t("yes");
    }
    return t("no");
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "transaction_type",
      name: t("wallet.label.WDtransactiontype"),

      key: "transaction_type",
      cell: ({ rowData }) => <div>{rowData?.transaction_type || "-"}</div>,
      sortable: false,
    },
    {
      id: "payment_type",
      name: t("payments.payable_type"),
      key: "payment_type",
      cell: ({ rowData }) => <>{rowData?.payable_target || "-"}</>,
    },
    {
      id: "payable_name",
      name: t("payments.payable_name"),
      key: "payable_name",
      cell: ({ rowData }) => <>{rowData?.payable_name || "-"}</>,
    },
    {
      id: "processed_by",
      name: t("payments.processed_by"),
      key: "processed_by",
      cell: ({ rowData }) => (
        <>
          {Object.keys(rowData?.wallet_transaction).length > 0
            ? rowData?.wallet_transaction?.actor[`name_${i18n.language}`]
            : "-"}
        </>
      ),
    },
    {
      id: "payment_courses",
      name: t("payments.payment_courses"),
      key: "payment_courses",
      cell: ({ rowData }) =>
        rowData?.covered_courses?.length > 0 ? (
          <p>{rowData?.covered_courses?.map((course) => course.name).join(" | ")}</p>
        ) : rowData?.coupon_courses ? (
          Array.isArray(rowData?.coupon_courses) ? (
            <p>{rowData?.coupon_courses?.map((course) => course.name).join(" | ")}</p>
          ) : (
            rowData?.coupon_courses?.name || "-"
          )
        ) : (
          "-"
        ),
    },
    // {
    //   id: "amount",
    //   name: t("wallet.label.WDamount"),
    //   key: "amount",
    //   cell: ({ rowData }) => (
    //     <ul>
    //       {rowData.amount} {t("rs")}
    //     </ul>
    //   ),
    //   sortable: false,
    // },
    // {
    //   id: "nametransactiondirection",
    //   name: t("wallet.label.WDnametransactiondirection"),

    //   key: "nametransactiondirection",
    //   cell: ({ rowData }) => <div>{rowData?.morph_data?.name || "-"}</div>,
    //   sortable: false,
    // },
    // {
    //   id: "transactiondirection",
    //   name: t("wallet.label.WDtransactiondirection"),

    //   key: "transactiondirection",
    //   cell: ({ rowData }) => <div>{rowData?.morph_type || "-"}</div>,
    //   sortable: false,
    // },
    {
      id: "gift",
      name: t("gift"),
      key: "gift",
      cell: ({ rowData }) => <>{displayGift(rowData?.is_gift) || "-"}</>,
    },
    {
      id: "origianl_price",
      name: t("payments.original_price"),
      key: "origianl_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.origianl_price)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "discount_price",
      name: t("payments.discount_price"),
      key: "discount_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.offer_amount)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "coupon_price",
      name: t("payments.coupon_price"),
      key: "coupon_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.coupon_amount)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "coupon_include_tax",
      name: t("payments.coupon_include_tax"),
      key: "coupon_include_tax",
      cell: ({ rowData }) => (
        <>{rowData?.payable_include_tax && rowData?.payable_include_tax !== "0" ? t("yes") : t("no")}</>
      ),
    },
    {
      id: "net_price",
      name: t("payments.net_price"),
      key: "net_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.net_ammount)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "tax_price",
      name: t("payments.tax_price"),
      key: "tax_price",
      cell: ({ rowData }) => (
        <div className={classes["price-minus"]}>{`${Number(rowData?.tax_amount)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "payment_price",
      name: t("payments.payment_price"),
      key: "payment_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.paid_amount)} ${t("rs")}` || "-"}</div>
      ),
    },
    // {
    //   id: "processed_by",
    //   name: t("general.processed_by"),
    //   key: "processed_by",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.processor ? (i18n.language === "ar" ? rowData.processor.name_ar : rowData.processor.name_en) : "-"}
    //     </>
    //   ),
    // },
    {
      id: "rejection_reason",
      name: t("financial.payments_request.rejected_reason"),
      key: "rejection_reason",
      cell: ({ rowData }) => (
        <div
          className={classes["rejection-reason"]}
          dangerouslySetInnerHTML={{ __html: rowData?.rejection_reason || "-" }}
        ></div>
      ),
    },
    {
      id: "accept_note",
      name: t("financial.payments_request.accept_note"),
      key: "accept_note",
      cell: ({ rowData }) => (
        <>
          <div style={{ width: 300 }}>
            <p className={classes["note_table"]}>
              {rowData?.accept_reason || rowData?.wallet_transaction?.notes || "-"}
            </p>
            {Object.keys(rowData?.wallet_transaction).length > 0 && (
              <p className={classes["note_table"]}>
                {t("general.attachment")}:{" "}
                <button
                  style={{
                    color: "#036c77",
                    textDecoration: "underline",
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                    display: "inline",
                  }}
                  onClick={async () => {
                    const response = await fetch(rowData?.wallet_transaction?.attachment?.url, {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
                      },
                    });
                    const blob = await response.blob();
                    window.open(URL.createObjectURL(blob), "_blank");
                  }}
                >
                  {rowData?.wallet_transaction?.attachment?.name}
                </button>
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      id: "created_at",
      name: t("wallet.label.WDtransactiondata"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {rowData?.created_at ? (
            <>
              {moment(rowData?.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.created_at)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
      sortable: false,
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => <>{displayStatus[rowData?.status] || "-"}</>,
    },
  ];
  return <MainTable columns={columns} data={walletTransactions || []} isLoading={isLoading} />;
};

export default WalletListTable;
