import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import { uploadFile } from "../../../../Services/api/files/filesProvider";
const SunEditorText = ({ value, setValue, dirEditor, minHeight, disabled = false }) => {
  require("./Suneditor.min.css");
  const { i18n } = useTranslation();
  const [refreshEditor, setRefreshEditor] = useState(false);

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("collection_name", "exhibitions");
    formData.append("key", "file");
    uploadFile(formData)
      .then((res) => {
        const response = {
          result: [
            {
              url: `${process.env.REACT_APP_SERVER_PATH}storage/exhibitions/${res.data.media.file_path}`,
              name: files[0].name,
              size: files[0].size,
            },
          ],
        };
        uploadHandler(response, info);
      })
      .catch((error) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold", display: "block" }}>{error?.response?.data?.msg}</span>
            {error?.response?.data?.errors && (
              <ul>
                {Object.keys(error.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{error.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        setRefreshEditor(true);
      });
  };

  useEffect(() => {
    if (refreshEditor) {
      setRefreshEditor(false);
    }
  }, [refreshEditor]);

  const onChange = (content) => {
    setValue(content);
  };

  return (
    !refreshEditor && (
      <SunEditor
        defaultValue={value}
        value={value}
        disable={disabled}
        onImageUploadBefore={handleImageUploadBefore}
        onChange={onChange}
        setDefaultStyle={`font-family: dinNextRegular, sans-serif; font-size: 16px;`}
        setOptions={{
          minHeight: "200px",
          rtl: i18n.language === "ar" ? true : false,
          buttonList: [
            ["undo", "redo"],
            ["fontSize", "formatBlock"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "table"],
            ["link", "image", "video"],
            ["fullScreen", "showBlocks", "codeView"],
            ["preview", "print"],
            ["fontColor", "hiliteColor"],
          ],
        }}
        minHeight={minHeight}
      />
    )
  );
};

export default SunEditorText;
