import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import IconTooltips from "../../../Components/tooltips/IconsTooltip";
import clearEmptyProp from "../../../Helpers/ClearEmptyProp";
import { decrypt } from "../../../Helpers/Secret";
import LogsModal from "../../../Modals/LogsModal/LogsModal";
import { exportLogs, getLogs } from "../../../Services/api/Logs/LogsProvider";
import ViewDetailsIcon from "../../../assets/icons/eye.svg";
import FilterIcon from "../../../assets/icons/filter.svg";
import PdfIcon from "../../../assets/icons/pdf.svg";
import XlsIcon from "../../../assets/icons/xls.svg";
import Input from "../../Shared/Components/Input/Input";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";

function Logs(props) {
  require("./Logs.css");
  const { t, i18n } = useTranslation();
  const logsModalRef = useRef();
  const deleteModalRef = useRef();
  const [meta, setMeta] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showCourses, setShowCourses] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});
  const [oldChanges, setOldChanges] = useState([]);
  const [newChanges, setNewChanges] = useState([]);
  const [logs, setLogs] = useState([]);
  const [models, setModels] = useState([]);
  const [actions, setActions] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] =
    useState(false);

  const fetchAllLog = (params = null) => {
    setIsUpdating(true);
    console.log(params);

    return new Promise((resolve, reject) => {
      getLogs(clearEmptyProp(params))
        .then((res) => {
          // console.log(res);

          if (res.status && res.status == 200 && res.data.status) {
            setLogs(res.data.response.log.data);
            setModels(res.data.response.models);
            // setActions(res.data.response.actions);
            setMeta(res.data.response.log.meta);
            setIsLoading(false);
            setIsUpdating(false);
            resolve();
          } else {
            reject("Error");
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("failed_fetching")}
            </span>
          );
        });
    });
  };

  useEffect(() => {
    fetchAllLog(currentFilter);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      document.getElementById("content-section").style.height =
        (
          parseInt(
            document.getElementsByClassName("tajah_table_vertical_movement")[0]
              .offsetHeight
          ) +
          parseInt(document.getElementById("content-header-bar").offsetHeight) +
          40
        ).toString() + "px";

      document.getElementById("content-body-section").style.height =
        parseInt(
          document.getElementsByClassName("tajah_table_vertical_movement")[0]
            .offsetHeight
        ).toString() + "px";
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      reAlignElementsHeights(showFilters);
    }
  }, [showFilters, logs]);

  const reAlignElementsHeights = (isFilterShown) => {
    if (!isFilterShown) {
      document.getElementById("content-section").style.height =
        (
          parseInt(
            document.getElementsByClassName("tajah_table_vertical_movement")[0]
              .offsetHeight
          ) +
          parseInt(document.getElementById("content-header-bar").offsetHeight) +
          40
        ).toString() + "px";

      document.getElementById("content-body-section").style.height =
        parseInt(
          document.getElementsByClassName("tajah_table_vertical_movement")[0]
            .offsetHeight
        ).toString() + "px";
    } else {
      document.getElementById("content-section").style.height =
        (
          parseInt(
            document.getElementsByClassName("tajah_table_fade_change")[0]
              .offsetHeight
          ) +
          parseInt(document.getElementById("content-header-bar").offsetHeight) +
          parseInt(
            document.getElementsByClassName("tajah_table_vertical_movement")[0]
              .offsetHeight
          ) +
          80
        ).toString() + "px";

      document.getElementById("content-body-section").style.height =
        (
          parseInt(
            document.getElementsByClassName("tajah_table_vertical_movement")[0]
              .offsetHeight
          ) +
          parseInt(
            document.getElementsByClassName("tajah_table_fade_change")[0]
              .offsetHeight
          ) +
          40
        ).toString() + "px";
    }
  };

  const handlePageClick = (event) => {
    const toBeUpdatedFilters = currentFilter;
    toBeUpdatedFilters.page = event.selected + 1;
    setCurrentFilter(toBeUpdatedFilters);
    fetchAllLog(toBeUpdatedFilters);
  };

  const modalHandler = () => deleteModalRef.current.dismissModal();

  const oldChange = (logsChange) => {
    logsModalRef.current.showModal();
    setShowCourses(false);
    let arr = [];
    for (const [key, value] of Object.entries(logsChange)) {
      arr.push({
        key: key,
        value: value,
      });
    }
    setOldChanges(arr);
  };

  const newChange = (logsChange, oldLogsChange) => {
    logsModalRef.current.showModal();
    setShowCourses(true);
    let arr = [];
    for (const [key, value] of Object.entries(logsChange)) {
      arr.push({
        key: key,
        value: value,
      });
    }
    arr.forEach((item) => {
      if (!oldLogsChange[item.key]) {
        item.oldValue = "-";
      } else {
        item.oldValue = oldLogsChange[item.key];
      }
    });
    setNewChanges(arr);
    // console.log(arr);
  };

  return (
    <>
      <BasicModal ref={logsModalRef}>
        {!showCourses ? (
          <LogsModal
            title={t("logs.label.old_changes")}
            refuse={modalHandler}
            logsChange={oldChanges}
            deleteModalRef={logsModalRef}
          />
        ) : (
          <LogsModal
            title={t("logs.label.new_changes")}
            refuse={modalHandler}
            logsChange={newChanges}
            deleteModalRef={logsModalRef}
          />
        )}
      </BasicModal>
      <div
        className="container-fluid"
        id={"content-section"}
        style={{
          position: "relative",
          padding: 0,
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: 50,
        }}
      >
        <div className="admin_flex" id="content-header-bar">
          <div className="admin_label">
            <NavLink
              to={`${
                decrypt(localStorage.getItem("type")) == "accountant"
                  ? "/accountant"
                  : "/admin"
              }`}
            >
              {t("admin.label.admin_label")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("logs.label.logs")}
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {isLoading == false ? (
          <div
            id="content-body-section"
            style={{
              position: "absolute",
              width: "100%",
            }}
          >
            {isPerformingBackgroundOperation ? (
              <SkeletonCardOverlay borderRadius={10} top={40} />
            ) : null}
            <div
              className={"tajah_table_fade_change"}
              style={{
                opacity: showFilters ? 1 : 0,
              }}
            >
              <Formik
                initialValues={{
                  model: "",

                  causer_name: "",
                  email: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    // setIsPerformingBackgroundOperation(true);
                    setCurrentFilter(values);
                    await fetchAllLog(values);
                    reAlignElementsHeights(showFilters);
                    // setIsPerformingBackgroundOperation(false);
                  } catch (e) {
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {t("failed_fetching")}
                      </span>
                    );
                  }
                }}
                validateOnChange={false}
              >
                {({ values, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit} className={"table_bank_tran"}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        width: "90%",
                        borderBottomWidth: 1,
                        borderBottomColor: "rgba(0,0,0,0.3)",
                        borderBottomStyle: "solid",
                        height: 40,
                      }}
                    >
                      <h5>{t("Filter")}</h5>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "90%",
                        marginTop: 30,
                      }}
                    >
                      {/* <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label style={{ color: "#777777" }}>
                          {t("logs.label.model")}
                        </label>
                        <Select
                          name="model"
                          id="model"
                          options={models}
                          getOptionLabel={(option) => option.value}
                          getOptionValue={(option) => option.value}
                          isClearable={true}
                          onChange={(status) => {
                            if (status) {
                              setFieldValue("model", status.value);
                            } else {
                              setFieldValue("model", null);
                            }
                          }}
                          placeholder={t("logs.label.model")}
                        />
                      </div> */}
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        {/* <label>{t("logs.label.actions")}</label> */}
                        <Input
                          label={t(`name`)}
                          type="text"
                          name="causer_name"
                          placeholder={t(`name`)}
                          onChange={(value) => {
                            setFieldValue("causer_name", value.target.value);
                          }}
                          value={values.causer_name}
                          // error={errors.name}
                          // value={values.name}
                          // required
                          // readonly={type === "preview" || type === "cannotEdit"}
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        {/* <label>{t("logs.label.actions")}</label> */}
                        <Input
                          label={t(`email`)}
                          type="text"
                          name="email"
                          placeholder={t(`email`)}
                          onChange={(value) => {
                            setFieldValue("email", value.target.value);
                          }}
                          value={values.email}
                          // error={errors.name}
                          // value={values.name}
                          // required
                          // readonly={type === "preview" || type === "cannotEdit"}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        width: "90%",
                        marginTop: 20,
                        gap: 10,
                      }}
                    >
                      <button
                        style={{
                          border: "2px solid #006D77",
                          color: "#006D77",
                          backgroundColor: "transparent",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: "130px",
                          padding: "10px",
                          borderRadius: "15px",
                          fontSize: "16px",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                        type="button"
                        onClick={() => {
                          setFieldValue("causer_name", "");
                          setFieldValue("email", "");
                          setCurrentFilter({});
                          fetchAllLog({});
                        }}
                      >
                        {t("general.reset")}
                      </button>
                      <button
                        style={{
                          backgroundColor: "#006D77",
                        }}
                        type="submit"
                        className="admin_add_button"
                      >
                        {t("crud.placeholders.submit")}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

            <div
              className="table_bank_tran tajah_table_vertical_movement"
              style={{
                top: showFilters
                  ? parseInt(
                      document.getElementsByClassName(
                        "tajah_table_fade_change"
                      )[0].offsetHeight
                    ) + 40
                  : 0,
              }}
            >
              <div className="tajah_card_title_container">
                <div>{t("logs.label.logs")}</div>
                <div className={"tajah_card_title_container_actions"}>
                  <button
                    style={{
                      borderRadius: 6,
                      padding: 10,
                      border: "2px solid #24b3b9",
                      width: 50,
                      height: 50,
                      backgroundColor: "transparent",
                    }}
                    className={
                      showFilters ? "btn btn-sm btn-danger" : "btn-bg-filter"
                    }
                    onClick={() => {
                      setShowFilters(!showFilters);
                    }}
                  >
                    <IconTooltips
                      title={t("modal.coupon.hover.search")}
                      content={<img src={FilterIcon} alt="" />}
                    />
                  </button>
                  <button
                    className={"btn btn-sm btn-primary color-button-pdf"}
                    style={{
                      marginRight: document.body.dir == "rtl" ? 10 : 0,
                      marginLeft: document.body.dir == "ltr" ? 10 : 0,
                      backgroundColor: "#b91414",
                      borderColor: "#b91414",
                      borderWidth: 1,
                      borderRadius: 6,
                      width: 50,
                      height: 50,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={async () => {
                      try {
                        setIsPerformingBackgroundOperation(true);
                        await exportLogs("pdf", clearEmptyProp(currentFilter));
                        setIsPerformingBackgroundOperation(false);
                      } catch (e) {
                        setIsPerformingBackgroundOperation(false);
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("failed_fetching")}
                          </span>
                        );
                      }
                    }}
                  >
                    <IconTooltips
                      title={t("modal.coupon.hover.titleDownloadPdf")}
                      content={<img src={PdfIcon} />}
                    />
                  </button>
                  <button
                    className={"btn btn-sm btn-primary color-button-excel"}
                    style={{
                      marginRight: document.body.dir == "rtl" ? 10 : 0,
                      marginLeft: document.body.dir == "ltr" ? 10 : 0,
                      backgroundColor: "#138d5f",
                      borderColor: "#138d5f",
                      borderWidth: 1,
                      borderRadius: 6,
                      width: 50,
                      height: 50,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={async () => {
                      try {
                        setIsPerformingBackgroundOperation(true);
                        await exportLogs("xlsx", clearEmptyProp(currentFilter));
                        setIsPerformingBackgroundOperation(false);
                      } catch (e) {
                        setIsPerformingBackgroundOperation(false);
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("failed_fetching")}
                          </span>
                        );
                      }
                    }}
                  >
                    <IconTooltips
                      title={t("modal.coupon.hover.titleDownloadExcel")}
                      content={<img src={XlsIcon} />}
                    />
                  </button>
                </div>
              </div>

              <div
                className="table-responsive"
                style={{
                  width: "90%",
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{t("logs.label.name")}</th>
                      <th scope="col">{t("logs.label.email")}</th>
                      <th scope="col">{t("logs.label.description")}</th>
                      <th scope="col">{t("logs.label.create_at")}</th>
                      <th scope="col">{t("logs.label.since")}</th>
                      <th scope="col">{t("logs.label.model")}</th>
                      <th scope="col">IP</th>
                      {/* <th scope="col">{t("logs.label.old_changes")}</th> */}
                      <th scope="col">{t("logs.label.new_changes")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((log, index) => {
                      return (
                        <tr key={log.id}>
                          <td scope="row">{index + 1}</td>

                          <td>
                            {i18n.language == "en"
                              ? log.causer_name_en
                              : log.causer_name_ar}
                          </td>
                          <td>{log.email}</td>
                          <td>{log.description}</td>
                          <td>{log.created_time.split("T")[0]}</td>
                          <td>{log.created_at}</td>
                          <td>{log.model.name}</td>
                          <td>{log.ip ? log.ip : "-"}</td>
                          {/* <td>
                            <div className="release_icon">
                              <IconTooltips
                                title={t("modal.coupon.buttons.data")}
                                content={
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      oldChange(log.old_changes.changes)
                                    }
                                    src={ViewDetailsIcon}
                                    alt=""
                                  />
                                }
                              />
                            </div>
                          </td> */}
                          <td>
                            <div className="release_icon">
                              <IconTooltips
                                title={t("modal.coupon.buttons.data")}
                                content={
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      newChange(
                                        log.new_changes.changes,
                                        log.old_changes.changes
                                      )
                                    }
                                    src={ViewDetailsIcon}
                                    alt=""
                                  />
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <ReactPaginate
                  nextLabel={t("next")}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={meta.totalPages}
                  previousLabel={t("previous")}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default Logs;
