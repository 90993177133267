import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "../../../../../assets/icons/download3.svg";
import { downloadFileFromLesson } from "../../../../../Services/api/courses/courseProvider";
import classes from "./ContentView.module.css";
function PdfView({ question }) {
  const { t } = useTranslation();
  const [linkURL, setLinkURL] = useState("");

  const handleDownload = () => {
    const type = "contnet";
    downloadFileFromLesson(question?.title, question?.extension, question?.id, type);
  };

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch(question.link, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
        },
      });
      const blob = await response.blob();
      setLinkURL(URL.createObjectURL(blob));
    };

    if (question.link) fetchImage();
  }, [question.link]);

  return (
    <div>
      <div style={{ paddingBottom: 15 }}>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          className={classes["course-slider-item-content-inner-body"]}
        />
      </div>
      {width <= 500 ? (
        <div
          className={classes["downloadFile"]}
          style={{
            marginBottom: "10px",
          }}
        >
          <button className={classes["file-download"]} type="button" onClick={() => handleDownload()}>
            <img
              src={DownloadIcon}
              alt="Download Files"
              className={classes["downloadicon"]}
              style={{
                background: "#29b3b81f",
                borderRadius: "10px",
                padding: "15px 15px",
                marginBottom: "10px",
              }}
            />
            <span style={{ fontWeight: "bold" }}>{t("download_files")}</span>
          </button>
        </div>
      ) : (
        <iframe
          src={linkURL}
          width="100%"
          height="500"
          frameborder="0"
          allowfullscreen
          embedded={true}
          title="PDF Preview"
        ></iframe>
      )}
    </div>
  );
}

export default PdfView;
