import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { decrypt } from "../../../../Helpers/Secret";
import { fetchRefundsList } from "../../../../Redux/Actions/refundSetting/refundSetting.action";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { getExhibitionList } from "../../../../Services/api/Exhibition/ExhibitionProvider";
import { exportRefundList } from "../../../../Services/api/RefundList";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import RefundSettingFilter from "./RefundSettingFilter";
import { filterActionsrRefund } from "./RefundSettingListActions";
import RefundTable from "./RefundTable";
import classes from "./refundList.module.css";

function RefundSettingList({ type }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [visitorExhibition, setVisitorExhibition] = useState([]);
  const [boothExhibition, setBoothExhibition] = useState([]);

  const [filterData, setFilterData] = useState({
    refund_title: "",
    mechanism: "",
    created_by: "",
    parent_type: type === "exhibition-visitors" ? 4 : type === "exhibition-booths" ? 3 : "",
    course: "",
    program: "",
    status: "",
    start_date: "",
    end_date: "",
  });

  const { refundsListMeta } = useSelector((state) => state.refundsListReducer);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `refund`,
      page: t(
        `financial.refund.${
          type === "exhibition-visitors"
            ? "exhibition_visitors_refunds"
            : type === "exhibition-booths"
            ? "exhibition_booth_refunds"
            : "refunds"
        }`
      ),
      pagePath: `/${decrypt(localStorage.getItem("type")) === "accountant" ? "accountant" : "admin"}/refund${
        type !== "trainee" ? `/${type}` : ""
      }`,
    },
    {
      id: `content_development_refund`,
      page: t("financial.label.refundSetting"),
      pagePath: "#",
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      refund_title: "",
      mechanism: "",
      created_by: "",
      parent_type: type === "exhibition-visitors" ? 4 : type === "exhibition-booths" ? 3 : "",
      course: "",
      program: "",
      status: "",
      start_date: "",
      end_date: "",
      booth: "",
      visitor: "",
    });
    setIsResetApplied(true);
  };

  const getRefundListHandler = () => {
    const filter = getFilterData();

    dispatch(
      fetchRefundsList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
  };

  const getFilterData = () => {
    const filter = {};
    filterData.refund_title && (filter.refund_title = filterData.refund_title);
    filterData.mechanism && (filter.mechanism = filterData.mechanism);
    filterData.created_by && (filterData.created_by == 1 ? (filter.by_admin = 1) : (filter.by_accountant = 1));
    filterData.parent_type && (filter.parent_type = filterData.parent_type);
    filterData.status && (filter.status = filterData.status);
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);
    switch (Number(filterData.parent_type)) {
      case 1:
        filterData.course && (filter.refundable_id = filterData.course);
        break;
      case 2:
        filterData.program && (filter.parent_id = filterData.program);
        break;
      case 3:
        filterData.booth && (filter.refundable_id = filterData.booth);
        break;
      case 4:
        filterData.visitor && (filter.refundable_id = filterData.visitor);
        break;
      default:
        break;
    }
    type === "trainee" && (filter.refundable_type = "course");

    return filter;
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      await exportRefundList(filter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const fetchData = async () => {
    try {
      const [coursesRes, programsRes, boothRes, visitorRes] = await Promise.all([
        getAllCourses({
          paginated: 0,
          not_in_program: 1,
          is_program: 0,
          mode: "Paid",
          perPage: 1000,
        }),
        getAllCourses({
          paginated: 0,
          is_program: 1,
          mode: "Paid",
          perPage: 1000,
        }),
        getExhibitionList({ perPage: 1000, mode: "Paid" }),
        getExhibitionList({ perPage: 1000, booth_mode: "Paid" }),
      ]);

      if (coursesRes.status === 200 && coursesRes.data.status) {
        setCourses(coursesRes.data.data.courses.data);
      }

      if (programsRes.status === 200 && programsRes.data.status) {
        setPrograms(programsRes.data.data.courses.data);
      }

      if (visitorRes.status === 200) {
        setVisitorExhibition(visitorRes.data.data.exhibitions.data);
      }

      if (boothRes.status === 200) {
        setBoothExhibition(boothRes.data.data.exhibitions.data);
      }
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error.response ? error.response.data.msg : t("Failure_in_service")}
        </span>
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getRefundListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  return (
    <div className={classes["refund-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["refund-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("financial.label.refundSetting")}
                  actions={filterActionsrRefund({
                    showFilter: showFilterHandler,
                    addAction: () => {
                      history.push(
                        `${
                          decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"
                        }/refund-setting${type !== "trainee" ? `/${type}` : ""}/add`
                      );
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <RefundSettingFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getRefundListHandler}
                    resetFilterHandler={resetFilterHandler}
                    courses={courses}
                    programs={programs}
                    visitorExhibition={visitorExhibition}
                    boothExhibition={boothExhibition}
                    type={type}
                  />
                )}

                <RefundTable type={type} />
                <Pagination
                  count={refundsListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundSettingList;
