import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AboutImage from "../../../../assets/image/AboutPic.png";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import classes from "./newHomeAbout.module.css";

const NewHomeAbout = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="container-fluid">
      <div className={classes.aboutSection}>
        <div className={classes.about}>
          <span>{t("about.about")}</span>
          <h2>{t("about.heading")}</h2>
          <p className={classes.aboutSubHeading}>{t("about.subHeading")}</p>
          <CustomButton
            value={t("about.aboutUs")}
            colors="#24b3b9"
            classes={classes.aboutButton}
            action={() => history.push("/about-us")}
          />
        </div>
        <div className={classes.aboutImage}>
          <img src={AboutImage} alt="MacImage" />
        </div>
      </div>
    </div>
  );
};

export default NewHomeAbout;
