import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { decrypt } from "../../../../Helpers/Secret";
import { getAdminCounters } from "../../../../Services/api/toutorials/AdminCounters";
import activeCoupons from "../../../../assets/icons/activeCoupons.svg";
import bankTransfersRecorded from "../../../../assets/icons/bankTransfersRecorded.svg";
import corners from "../../../../assets/icons/corners.svg";
import courseNum from "../../../../assets/icons/courseNum.svg";
import exhibitions from "../../../../assets/icons/exhibitionsIMG.svg";
import paymentsDue from "../../../../assets/icons/paymentsToday.svg";
import refundRequests from "../../../../assets/icons/refundRequests.svg";
import sectionNum from "../../../../assets/icons/sectionNum.svg";
import close from "../../../../assets/icons/ticketsClose.svg";
import open from "../../../../assets/icons/ticketsOpen.svg";
import traineeIMG from "../../../../assets/icons/traineeIMG.svg";
import trainerIMG from "../../../../assets/icons/trainerIMG.svg";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import CounterNumbers from "./CounterNumbers";

function CounterNumbersListTop(props) {
  require("./CounterNumbersListTop.css");
  const { t } = useTranslation();
  const [adminCounter, setAdminCouter] = useState([]);

  useEffect(() => {
    getAdminCounters()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setAdminCouter(res.data.counters);
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <div style={{ marginBottom: "30px" }}>
      {!props.isLoading ? (
        <div
          style={{
            height: "34vh",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <div className="counter_number">
          <div className="counter_number_grid">
            <CounterNumbers
              image={paymentsDue}
              number={props?.counter?.paymentsToday}
              label={t("dashBoard.label.paymentsDue")}
              link={`${
                decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"
              }/program-payment-date?program_payment_date=all`}
            />
            <CounterNumbers
              image={bankTransfersRecorded}
              number={props?.counter?.totalWaitingBankTransfer}
              label={t("dashBoard.label.bankTransfersRecorded")}
              link={`${
                decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"
              }/bank-transfers`}
            />
            <CounterNumbers
              image={refundRequests}
              number={props?.counter?.totalCurrentRefunds}
              label={t("dashBoard.label.refundRequests")}
              link={`${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/refund`}
            />
            {decrypt(localStorage.getItem("type")) === "admin" ? (
              <>
                <CounterNumbers
                  image={trainerIMG}
                  number={adminCounter?.trainers}
                  label={t("admin.label.num_trainer")}
                  link="/admin/roles/trainer"
                />
                <CounterNumbers
                  image={sectionNum}
                  number={adminCounter?.departments}
                  label={t("admin.label.num_dep")}
                  link="/admin/department"
                />
                <CounterNumbers
                  image={traineeIMG}
                  number={adminCounter?.trainees}
                  label={t("admin.label.num_trainee")}
                  link="/admin/roles/trainee"
                />
                <CounterNumbers image={courseNum} number={adminCounter?.courses} label={t("admin.label.num_course")} />
                <CounterNumbers
                  image={corners}
                  number={adminCounter?.cornersExhibitions}
                  label={t("admin.label.num_corner")}
                />
                <CounterNumbers
                  image={exhibitions}
                  number={adminCounter?.exhibitions}
                  label={t("admin.label.num_virtual_exhibitions")}
                  link="/admin/exhibitions-list"
                />
                <CounterNumbers
                  image={open}
                  number={adminCounter?.ticketsOpen}
                  label={t("admin.label.open")}
                  link="/admin/tickets?status=new"
                />
                <CounterNumbers
                  image={close}
                  number={adminCounter?.ticketsClose}
                  label={t("admin.label.close")}
                  link="/admin/tickets?status=closed"
                />
                <CounterNumbers
                  image={activeCoupons}
                  number={adminCounter?.coupons}
                  label={t("admin.label.activeCoupons")}
                />
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default CounterNumbersListTop;
