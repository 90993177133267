import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import walletIcon from "../../../../assets/icons/wallet.png";
import { decrypt } from "../../../../Helpers/Secret";
import { Lang } from "../../../../utils";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import classes from "./UserWalletList.module.css";

const UserWalletListTable = ({ sortAction, rolesEditHandle }) => {
  const history = useHistory();

  const { systemUsersList, systemUsersListLoading } = useSelector((state) => state.systemUsersListReducer);

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const displayGender = (gender) => {
    return Number(gender) === 1 ? t("male") : t("female");
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "name",
      name: t("system_users.name"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => <div className={classes["system-users-list__table-name"]}>{rowData?.name || "-"}</div>,
      sortable: false,
    },
    {
      id: "email",
      name: t("system_users.email"),
      key: "email",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-email__wrapper"]}>
          <a className={classes["system-users-list__table-email"]} href={`mailto: ${rowData?.email}`}>
            {rowData?.email}
          </a>
        </div>
      ),
      sortable: false,
    },
    {
      id: "jobs",
      name: t("system_users.jobs"),
      key: "role",
      cell: ({ rowData }) => (
        <ul className={classes["system-users-list__table-jobs"]}>
          {!!rowData.roles &&
            rowData.roles.map((job) => (
              <li className={classes["system-users-list__table-jobs-item"]} key={job?.id}>
                {job?.label || "-"}
              </li>
            ))}
        </ul>
      ),
      sortable: false,
    },
    {
      id: "phone",
      name: t("system_users.phone"),
      key: "phone",
      cell: ({ rowData }) => <div style={{ direction: "ltr" }}>{rowData?.phone || "-"}</div>,
      sortable: false,
    },
    {
      id: "id_number",
      name: t("system_users.id_number"),
      key: "id_number",
      cell: ({ rowData }) => <>{rowData?.id_number || "-"}</>,
      sortable: false,
    },
    {
      id: "iban",
      name: t("wallet.label.iban"),
      key: "iban",
      cell: ({ rowData }) => <>{rowData?.iban || "-"}</>,
      sortable: false,
    },
    {
      id: "gender",
      name: t("system_users.gender"),
      key: "gender",
      cell: ({ rowData }) => <>{rowData?.gender ? displayGender(rowData.gender) : "-"}</>,
      sortable: false,
    },
    {
      id: "nationality",
      name: t("system_users.nationality"),
      key: "nationality",
      cell: ({ rowData }) => <>{rowData?.nationality?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "country",
      name: t("system_users.country"),
      key: "country",
      cell: ({ rowData }) => <>{rowData?.country?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "register_date",
      name: t("system_users.register_date"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: true,
    },
    {
      id: "actions",
      name: t("system_users.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table__actions"]}>
          <button
            className={classes["system-users-list__table__actions-btn"]}
            title={"Wallet"}
            onClick={() => {
              history.push(
                `/${
                  decrypt(localStorage.getItem("type")) == "accountant" ? "accountant" : "admin"
                }/users-wallet/details/${rowData.id}`
              );
            }}
          >
            <img
              src={walletIcon}
              alt=""
              style={{
                width: "24px",
              }}
            />
          </button>
        </div>
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("system_users.status"),
      key: "status",
      cell: ({ rowData }) => (rowData.status == 1 ? t("general.active") : t("general.inactive")),
      sortable: false,
    },
  ];
  return (
    <MainTable
      columns={columns}
      data={systemUsersList || []}
      isLoading={systemUsersListLoading}
      sortAction={sortAction}
    />
  );
};

export default UserWalletListTable;
