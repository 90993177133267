import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../assets/icons/remove-red.svg";
import { downloadFile } from "../../../../utils/downloadFile";
import Input from "../Input/Input";
import classes from "./paymentDetailsModal.module.css";

const PaymentDetailsModal = ({ closeModal, payment }) => {
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch(payment?.payment_proof, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
        },
      });
      const blob = await response.blob();
      setImageURL(URL.createObjectURL(blob));
    };

    if (!payment?.payment_proof.includes(".pdf")) {
      fetchImage();
    }
  }, [payment?.payment_proof]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const inputFields = [
    {
      label: t("financial.refund.account_holder_name"),
      type: "text",
      name: "user_name",
      value: payment?.name,
      readonly: true,
    },
    {
      label: t("financial.refund.phone_number"),
      type: "text",
      name: "phone",
      value: payment?.mobile,
      readonly: true,
    },
    {
      label: t("financial.refund.bank_name"),
      type: "text",
      name: "bank_name",
      value: payment?.bank,
      readonly: true,
    },
    {
      label: t("financial.refund.IBAN_number"),
      type: "text",
      name: "iban",
      value: payment?.iban,
      readonly: true,
    },
    {
      label: t("payments.transfer_date"),
      type: "text",
      name: "transfer_date",
      value: payment?.transfer_date,
      readonly: true,
    },
  ];

  const checkStatusClass = {
    Approved: classes["approved"],
    Rejected: classes["reject"],
  };
  const checkDisplay = {
    Approved: t("booth.approve_msg"),
    Rejected: t("booth.reject_msg"),
  };

  return (
    <div className={classes["exhibition-payment-modal"]}>
      <div className={classes["exhibition-payment-modal__container"]} ref={modalRef}>
        <div
          className={classes["exhibition-payment-modal__close"]}
          onClick={() => {
            closeModal();
          }}
        >
          <img src={CloseIcon} alt="" />
        </div>
        <div className={classes["exhibition-payment-modal__header"]}>
          <div className={classes["exhibition-payment-modal-title"]}>{t("Exhibition.bank_transfers")}</div>
          <div className={classes["exhibition-payment-modal-value"]}>
            <div className={classes["exhibition-payment-modal-value-label"]}>{t("Exhibition.amount_required")}</div>

            <div className={classes["exhibition-payment-modal-value-box"]}>
              {payment?.paid_amount}
              <sub>{t("Exhibition.sar_currency")}</sub>
            </div>
            {payment?.status !== "Waiting" && (
              <div className={classes["exhibition-payment-modal-status"]}>
                <div
                  className={`${classes["exhibition-payment-modal-status-box"]} ${checkStatusClass[payment?.status]}`}
                >
                  {checkDisplay[payment?.status]}
                </div>
              </div>
            )}
          </div>
        </div>
        {payment?.status === "Rejected" && (
          <div className={classes["exhibition-payment-modal_status-reason"]}>
            {t("booth.reject_reason")}: <span>{payment?.rejection_reason || ""}</span>
          </div>
        )}

        <div className={classes["exhibition-payment-modal__content"]}>
          {inputFields.map((input) => (
            <div className={classes["exhibition-payment-modal__field"]} key={input.name}>
              <Input label={input.label} type={input.type} name={input.name} value={input.value} readonly />
            </div>
          ))}
          <div className={classes["exhibition-payment-modal__image"]}>
            <img src={imageURL} alt="" />
            <button
              className={classes["exhibition-payment-modal__image-download"]}
              onClick={() => downloadFile(payment?.payment_proof)}
            >
              <i className="fa fa-download"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsModal;
