import { motion, useAnimation, useInView } from "framer-motion/dist/framer-motion";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classes from "./CarosalHomePage.module.css";

export default function CaroselSlide({ ad, adListLoading, key }) {
  const { t, i18n } = useTranslation();
  const ref = useRef(null);

  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    // console.log(ad.id, isInView);
    if (isInView && adListLoading) {
      mainControls.start("visible");
      // setisvisible(true);
    }
  }, [isInView, adListLoading]);
  return (
    <>
      <div
        className={classes["main"]}
        style={{
          backgroundImage: `url(${encodeURI(ad?.[i18n.language === "ar" ? "img_desktop_ar" : "img_desktop_en"])})`,
          cursor: ad?.url ? "pointer" : "",
        }}
        onClick={() => (ad?.url ? window.open(ad?.url, "_blank") : null)}
      >
        <div className={classes.backdropDiv}>
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 120 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={classes["main__wrapper"]}
          >
            {ad?.show_content !== "0" && (
              <div className={classes["main__content"]}>
                <h2 className={classes["main__content-title"]}>{ad?.title}</h2>
                <p
                  className={classes["main__content-desc"]}
                  style={ad?.description?.length > 710 ? { fontSize: "14px" } : {}}
                >
                  {ad?.description}
                </p>
              </div>
            )}
            {/* {ad?.has_button && (
            <div className={classes["search_container"]}>
            <CustomButton
            value={ad?.button_text}
                colors={"#fff"}
                classes={classes.btnRegister}
                action={() => window.open(ad?.url, "_blank")}
                />
                </div>
                )} */}
          </motion.div>
        </div>
      </div>
      <div
        className={classes["main__mobile"]}
        style={{
          backgroundImage: `url(${encodeURI(
            ad?.[
              i18n.language === "ar"
                ? ad?.img_mobile_ar
                  ? "img_mobile_ar"
                  : "img_desktop_ar"
                : ad?.img_mobile_en
                ? "img_mobile_en"
                : "img_desktop_en"
            ]
          )})`,
          cursor: ad?.url ? "pointer" : "",
        }}
        onClick={() => (ad?.url ? window.open(ad?.url, "_blank") : null)}
      >
        <div className={classes.backdropDiv}>
          <motion.div
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 120 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.6, delay: 0.3 }}
            className={classes["main__wrapper"]}
          >
            {ad?.show_content !== "0" && (
              <div className={classes["main__content"]}>
                <h2 className={classes["main__content-title"]}>{ad?.title}</h2>
                <p
                  className={classes["main__content-desc"]}
                  style={ad?.description?.length > 710 ? { fontSize: "14px" } : {}}
                >
                  {ad?.description}
                </p>
              </div>
            )}
            {/* {ad?.has_button && (
            <div className={classes["search_container"]}>
            <CustomButton
            value={ad?.button_text}
                colors={"#fff"}
                classes={classes.btnRegister}
                action={() => window.open(ad?.url, "_blank")}
                />
                </div>
                )} */}
          </motion.div>
        </div>
      </div>
    </>
  );
}
