import { Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { decrypt } from "../../../../../Helpers/Secret";
import {
  JPEG_TYPE,
  JPG_TYPE,
  PNG_TYPE,
  fromKbToMb,
  validateImage,
} from "../../../../../Services/Validator";
import { getListType } from "../../../../../Services/api/TypeFile/TypeFileProvider";
import { AuthContext } from "../../../../../Services/api/auth/AuthContext";
import {
  alternativeEmail,
  getCities,
  getCountries,
  getMedicals,
  getProfileInfo,
  getSpecialties,
  updateEmail,
  updateProfile,
  updateUser,
  verifyEmail,
} from "../../../../../Services/api/profile/profileProvider";
import editProfile from "../../../../../assets/icons/edit-profile.svg";
import plusProfile from "../../../../../assets/icons/plus-profile.svg";
import exit from "../../../../../assets/icons/remove-red.svg";
import userProfile from "../../../../../assets/icons/user-profile.svg";
import i18n from "../../../../../i18n/i18n";
import { Lang } from "../../../../../utils";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../../Shared/Components/Input/Input";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainDatePicker from "../../../../Shared/Components/MainDatePicker/MainDatePicker";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import PhoneInput from "../../../../Shared/Components/PhoneInput/PhoneInput";
import Select from "../../../../Shared/Components/Select/Select";
import SelectWithSearch from "../../../../Shared/Components/SelectWithSearch/SelectWithSearch.jsx";
import WarningModal from "../../../../Shared/Components/WarningModal/WarningModal";
import RadioButton from "../../../../Shared/Components/radioButton/RadioButton";
import "./profile.css";

export default function Profile({
  preview = false,
  userData = {},
  setUserData,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const inputFile = useRef(null);
  const emailModal = useRef(null);
  const completeProfileModal = useRef(null);
  const { id } = useParams();
  const roles = JSON.parse(decrypt(localStorage.getItem("roles")) || "[]");
  const role = roles && roles.length > 0 ? roles[0] : "";

  // const [userData, setUserData] = useState({});
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  // const [categoriesState, setCategoriesState] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [medicals, setMedicals] = useState([]);
  // const [hasInitializedData, setHasInitializedData] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [toBeShownImage, setToBeShownImage] = useState("");
  // const [radioButtonStateQuestion, setRadioButtonStateQuestion] = useState("3");
  const [updatePercentage, setUpdatePercentage] = useState(null);
  const [showCompleteProfileModal, setShowCompleteProfileModal] =
    useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailModalType, setEmailModalType] = useState("");
  const [listTypeFile, setListTypeFile] = useState([]);
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    userData.img !== "default.png" && setToBeShownImage(userData.img);
  }, [userData]);

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          resolve();
        },
        (error) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("programs.failed_loading")}
            </span>
          );
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getSpecialties()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setSpecialties(res.data.data.specialties.data);
          if (userData.specialty_id) {
            setDepartments(
              res.data.data.specialties.data.filter(
                (specialty) => specialty.id === Number(userData.specialty_id)
              )[0].departments
            );
          }
        }
      })
      .catch((err) => {});

    getProfileInfo()
      .then((res) => {
        if (res.status && res.status === 201 && res.data.status) {
          setUpdatePercentage(res.data.response.updatePercentage);
        }
      })
      .catch((err) => {});

    getCountries()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCountries(res.data.countries);

          if (userData.country_id) {
            getCities(userData.country_id)
              .then((res) => {
                if (res.status && res.status === 200 && res.data.status) {
                  setCities(res.data.cities);
                }
              })
              .catch((err) => {});
          }
        }
      })
      .catch((err) => {});

    getMedicals()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setMedicals(res.data.fields);
        }
      })
      .catch((err) => {});
  }, []);

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours == "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (updatePercentage !== null && updatePercentage !== 100 && !preview) {
      handleOpenCompleteProfileModal();
    }
  }, [updatePercentage]);

  const handleOpenEmailModal = (type) => {
    setLoading(false);
    setEmailModalType(type);
    setShowEmailModal(true);
    emailModal.current.showModal();
  };

  const handleCloseEmailModal = () => {
    setLoading(false);
    setEmailModalType("");
    setShowEmailModal(false);
    emailModal.current.dismissModal();
  };

  const handleOpenCompleteProfileModal = () => {
    setShowCompleteProfileModal(true);
    completeProfileModal.current.showModal();
  };

  const handleCloseCompleteProfileModal = () => {
    setShowCompleteProfileModal(false);
    completeProfileModal.current.dismissModal();
  };

  const handleVerifyEmail = () => {
    verifyEmail(id)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {res.data.msg ? (
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  {res.data.msg}
                </span>
              ) : null}
            </span>
          );
          setUserData({ ...userData, email_verified_at: true });
        }
      })
      .catch((err) => {});
  };

  const getPhoneCode = (phone) => {
    // console.log(phone.split()[0] !== "+");

    if (phone[0] !== "+") {
      let phoneCode = "+966";
      return phoneCode;
    }
    let phoneCode = phone.split(" ")[0];
    return phoneCode;
  };

  const getPhoneNumber = (phone) => {
    let phoneNumber = phone.split(" ")[1];
    if (phoneNumber) {
      return phoneNumber;
    } else {
      return Number(phone);
    }
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const getTypeFile = (searchType, searchType_2) => {
    return listTypeFile.filter(
      (file) => file.type === searchType || file.type === searchType_2
    );
  };

  const validateFile = (file) => {
    return validateImage(file, getTypeFile(PNG_TYPE, JPG_TYPE)[0]?.max_size);
  };

  return (
    <>
      <MainBox>
        <div className="profile-title-container">
          <h1 className="profile-title">{t("personal_info")}</h1>
          {updatePercentage !== null && updatePercentage !== 100 && (
            <div className="profile-warning">
              {t("editProfile.complete_profile_msg")}
            </div>
          )}
          {userData.is_email_generated === "1" && !id && (
            <div className="profile-warning">
              {t("editProfile.email_generated_msg")}
            </div>
          )}
          {userData.type_login === "sso" &&
            userData.alternative_email === "" && (
              <div className="profile-warning">
                {t("editProfile.alternative_email_msg")}
              </div>
            )}
        </div>
        <Formik
          initialValues={{
            name_en: userData.name_en ? userData.name_en : "",
            name_ar: userData.name_ar ? userData.name_ar : "",
            phone: userData.phone ? userData.phone : "",
            code: userData.phone ? getPhoneCode(userData.phone) : "",
            mobile: userData.phone ? getPhoneNumber(userData.phone) : "",
            date_of_birth: userData.date_of_birth
              ? new Date(userData.date_of_birth)
              : "",
            gender: userData.gender ? userData.gender : "",
            img: userData.img ? userData.img : "",
            country_id: userData.country_id ? userData.country_id : "",
            city_id: userData.city_id ? userData.city_id : "",
            nationality_id: userData.nationality_id
              ? userData.nationality_id
              : "",
            id_number: userData.id_number ? userData.id_number : "",
            interests: ["1", "2"],
            email:
              userData.email && userData.is_email_generated !== "1"
                ? userData.email
                : "",
            alternative_email: userData.alternative_email
              ? userData.alternative_email
              : "",
            is_medical: userData.is_medical ? userData.is_medical : "0",
            specialty_id: userData.specialty_id ? userData.specialty_id : "",
            department_id: userData.department_id ? userData.department_id : "",
            scghs: userData.scghs ? userData.scghs : "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              let dataToBeUploaded = {
                ...values,
              };
              dataToBeUploaded.phone = `${dataToBeUploaded.code} ${dataToBeUploaded.mobile}`;
              delete dataToBeUploaded.code;
              delete dataToBeUploaded.mobile;
              dataToBeUploaded.phone.trim() === "" &&
                delete dataToBeUploaded.phone;

              dataToBeUploaded.date_of_birth =
                formatDate(new Date(dataToBeUploaded.date_of_birth)) +
                " " +
                formatTime(new Date(dataToBeUploaded.date_of_birth));

              if (dataToBeUploaded.is_medical === "0") {
                delete dataToBeUploaded.specialty_id;
                delete dataToBeUploaded.department_id;
                delete dataToBeUploaded.scghs;
              }

              let formData = new FormData();
              Object.keys(dataToBeUploaded).forEach((field) => {
                if (
                  typeof dataToBeUploaded.img === "string" &&
                  field === "img"
                ) {
                  return;
                }
                formData.append(field, dataToBeUploaded[field]);
              });
              formData.append("interests[]", dataToBeUploaded["interests"]);
              let response = id
                ? await updateUser(formData, id)
                : await updateProfile(formData);
              if (response.status === 200 && response.data.status) {
                toast.success(
                  <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    {t("editProfile.success")}
                  </span>
                );
                if (!id) {
                  localStorage.setItem("name_ar", dataToBeUploaded.name_ar);
                  localStorage.setItem("name_en", dataToBeUploaded.name_en);
                  if (typeof dataToBeUploaded.img === "object") {
                    getBase64(dataToBeUploaded.img).then((base64) => {
                      localStorage.setItem("img", base64);
                    });
                  }
                  await getProfileInfo()
                    .then((res) => {
                      if (res.status && res.status === 201 && res.data.status) {
                        localStorage.setItem(
                          "completed_profile",
                          res.data.response.updatePercentage === 100
                            ? "true"
                            : "false"
                        );
                      }
                    })
                    .catch((err) => {});
                }
                history.push(id ? "/admin/roles" : "/private-profile");
              }
              setSubmitting(false);
            } catch (err) {
              toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {typeof err === "string" ? (
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: "500",
                        display: "block",
                      }}
                    >
                      {err}
                    </span>
                  ) : err.response.data ? (
                    <>
                      <span
                        style={{
                          fontSize: 13,
                          fontWeight: "bold",
                          display: "block",
                        }}
                      >
                        {err.response.data.msg}
                      </span>
                      <ul>
                        {Object.keys(err.response.data.errors).map((key) => {
                          return (
                            <li>
                              <span style={{ fontSize: 11 }}>
                                {err.response.data.errors[key]}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    " Error"
                  )}
                </span>
              );
            }
          }}
          // enableReinitialize={!hasInitializedData}
          validateOnChange={true}
          validate={(values) => {
            // setHasSubmitted(true);
            console.log(values);
            const errors = {};
            if (!values.name_ar) {
              errors.name_ar = t("crud.errors.required");
            }
            if (!values.name_en) {
              errors.name_en = t("crud.errors.required");
            }
            if (!values.email) {
              errors.email = t("crud.errors.required");
            }
            if (
              values.email &&
              !values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            ) {
              errors.email = t("crud.errors.invalidEmail");
            }
            if (values.mobile && !values.code) {
              errors.mobile = t("crud.errors.required");
            }

            if (values.code && !values.mobile) {
              errors.mobile = t("crud.errors.required");
            }

            if (!values.gender) {
              errors.gender = t("crud.errors.required");
            }

            if (!values.country_id) {
              errors.country_id = t("crud.errors.required");
            }
            if (!values.nationality_id) {
              errors.nationality_id = t("crud.errors.required");
            }
            if (!values.id_number) {
              errors.id_number = t("crud.errors.required");
            }
            if (!values.date_of_birth) {
              errors.date_of_birth = t("crud.errors.required");
            }

            if (!values.is_medical) {
              errors.is_medical = t("crud.errors.required");
            }

            if (values.is_medical === "1") {
              if (!values.specialty_id) {
                errors.specialty_id = t("crud.errors.required");
              }
              if (!values.department_id) {
                errors.department_id = t("crud.errors.required");
              }
            }
            return errors;
          }}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="personal-details-container">
                <div className="width-100">
                  <div className="profile-image-container">
                    <div
                      className="profile-image"
                      onClick={() => {
                        inputFile.current.click();
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        let file = e.dataTransfer.files[0];
                        if (validateFile(file)) {
                          let reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onload = function () {
                            setFieldValue("img", file);
                            setToBeShownImage(reader.result);
                          };
                          reader.onerror = function (error) {};
                        }
                      }}
                    >
                      <img
                        src={toBeShownImage ? toBeShownImage : userProfile}
                        alt="profile"
                        className="profile-image"
                      />
                      <div className="profile-image-plus">
                        <img
                          src={plusProfile}
                          alt="plus"
                          className="profile-image-plus-icon"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="profile-image-text">
                        {t("editProfile.upload_image")}
                      </p>
                      <p
                        className="profile-image-text"
                        style={{ color: "red" }}
                      >{`${t("editProfile.upload_image_note")} ${fromKbToMb(
                        Math.max(
                          getTypeFile(PNG_TYPE)[0]?.max_size,
                          getTypeFile(JPEG_TYPE)[0]?.max_size,
                          getTypeFile(JPG_TYPE)[0]?.max_size
                        )
                      )} ${t("megabyte")}`}</p>
                    </div>
                    <input
                      type="file"
                      name="img"
                      id="img"
                      accept="jpg,png,jpeg"
                      ref={inputFile}
                      className="profile-image-input"
                      onChange={(e) => {
                        let file = e.target.files[0];
                        if (file) {
                          let reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onload = function () {
                            setFieldValue("img", file);
                            setToBeShownImage(reader.result);
                          };
                          reader.onerror = function (error) {};
                        }
                      }}
                      disabled={preview}
                    />
                  </div>
                </div>
                {!id ? (
                  userData.is_email_generated === "0" ? (
                    <div className="width-100">
                      <Input
                        type="email"
                        label={t("email")}
                        name="email"
                        placeholder={t("email")}
                        onChange={handleChange}
                        value={values.email}
                        error={errors.email}
                        readonly={true}
                        required
                        direction="ltr"
                      />
                    </div>
                  ) : null
                ) : role === "admin" && userData.email_verified_at !== null ? (
                  <div className="width-100">
                    <Input
                      type="email"
                      label={t("email")}
                      name="email"
                      placeholder={t("email")}
                      onChange={handleChange}
                      value={values.email}
                      error={errors.email}
                      readonly={true}
                      required
                      direction="ltr"
                    />
                  </div>
                ) : null}
                {((!id && userData.is_email_generated === "1") ||
                  (id &&
                    role === "admin" &&
                    userData.email_verified_at === null)) && (
                  <>
                    <div className="width-65">
                      <Input
                        type="email"
                        label={
                          userData.type_login === "sso"
                            ? t("alternative_email")
                            : t("email")
                        }
                        name={
                          userData.type_login === "sso"
                            ? "alternative_email"
                            : "email"
                        }
                        placeholder={
                          userData.type_login === "sso"
                            ? t("alternative_email")
                            : t("email")
                        }
                        onChange={handleChange}
                        value={
                          userData.type_login === "sso"
                            ? userData.alternative_email
                            : values.email
                        }
                        error={
                          userData.type_login === "sso"
                            ? errors.alternative_email
                            : errors.email
                        }
                        readonly={true}
                        required
                        direction="ltr"
                      />
                    </div>
                    <div className="email-actions width-35">
                      <p className="email-actions-title">
                        {userData.type_login === "sso"
                          ? t("editProfile.alternative_email_msg")
                          : userData.is_email_generated === "1"
                          ? t("editProfile.email_generated_msg")
                          : role === "admin" &&
                            userData.email_verified_at === null
                          ? t("editProfile.email_not_verified")
                          : null}
                      </p>
                      <CustomButton
                        value={
                          !id
                            ? t("editProfile.add_email")
                            : t("editProfile.verify_email")
                        }
                        colors="#036c77"
                        type="button"
                        action={
                          role === "admin" &&
                          userData.email_verified_at === null
                            ? handleVerifyEmail
                            : () => handleOpenEmailModal("main")
                        }
                      />
                    </div>
                  </>
                )}
                <>
                  <div className="width-65">
                    <Input
                      type="email"
                      label={t("alternative_email")}
                      name={"alternative_email"}
                      placeholder={t("alternative_email")}
                      onChange={handleChange}
                      value={userData.alternative_email}
                      error={errors.alternative_email}
                      readonly={true}
                      direction="ltr"
                    />
                  </div>
                  <div className="email-actions width-35">
                    <p className="email-actions-title">
                      {userData.type_login === "sso"
                        ? t("editProfile.alternative_email_msg")
                        : t("editProfile.enter_alternative_email")}
                    </p>
                    <CustomButton
                      value={t("editProfile.add_email")}
                      colors="#036c77"
                      type="button"
                      action={() => handleOpenEmailModal("alternative")}
                    />
                  </div>
                </>
                <div className="width-50">
                  <Input
                    type="text"
                    label={t("nameAr")}
                    name="name_ar"
                    placeholder={t("nameAr")}
                    onChange={handleChange}
                    value={values.name_ar}
                    error={errors.name_ar}
                    readonly={
                      preview ||
                      (userData.type_login === "sso"
                        ? userData.is_updated === "1"
                        : userData.name_ar && !id && !userData.is_admin)
                    }
                    required
                  />
                </div>
                <div className="width-50">
                  <Input
                    type="text"
                    label={t("nameEn")}
                    name="name_en"
                    placeholder={t("nameEn")}
                    onChange={handleChange}
                    value={values.name_en}
                    error={errors.name_en}
                    readonly={
                      preview ||
                      (userData.type_login === "sso"
                        ? userData.is_updated === "1"
                        : userData.name_en && !id && !userData.is_admin)
                    }
                    required
                  />
                </div>
                <div className="width-100" style={{ direction: "ltr" }}>
                  <PhoneInput
                    label={t("phone")}
                    type="number"
                    nameInput="mobile"
                    nameSelect="code"
                    value={values.mobile}
                    selectValue={values.code}
                    placeholder={t("phone")}
                    onChange={handleChange}
                    error={errors.mobile}
                    min={0}
                    readonly={
                      preview || (userData.phone && !id && !userData.is_admin)
                    }
                  />
                </div>
                <div className="width-50">
                  <Select
                    label={t("gender")}
                    name="gender"
                    onchange={handleChange}
                    options={[
                      {
                        id: "male",
                        value: "1",
                        label: t("male"),
                      },
                      {
                        id: "female",
                        value: "2",
                        label: t("female"),
                      },
                    ]}
                    value={values.gender}
                    error={errors.gender}
                    initialValue={t("gender")}
                    disabled={
                      preview || (userData.gender && !id && !userData.is_admin)
                    }
                    required
                  />
                </div>
                <div className="width-50">
                  <MainDatePicker
                    label={t("birth")}
                    name="date_of_birth"
                    error={errors.date_of_birth}
                    value={values.date_of_birth}
                    onChange={(e) => {
                      setFieldValue("date_of_birth", e);
                    }}
                    minTime={new Date("1900-01-01")}
                    maxDate={new Date()}
                    readOnly={
                      preview ||
                      (userData.date_of_birth && !id && !userData.is_admin)
                    }
                    required
                    dayMonthYear
                  />
                </div>
                <div className="width-50">
                  <SelectWithSearch
                    label={t("country")}
                    name="country_id"
                    onchange={(value) => {
                      setFieldValue("country_id", value.value);
                      setFieldValue("city_id", "");
                      getCities(value.value)
                        .then((res) => {
                          if (
                            res.status &&
                            res.status === 200 &&
                            res.data.status
                          ) {
                            setCities(res.data.cities);
                          }
                        })
                        .catch((err) => {});
                    }}
                    options={countries.map((country) => {
                      return {
                        id: country.id,
                        value: country.id,
                        label: country.name,
                      };
                    })}
                    value={{
                      id: values.country_id,
                      value: values.country_id,
                      label: countries.filter(
                        (country) => country.id === Number(values.country_id)
                      )[0]?.name,
                    }}
                    error={errors.country_id}
                    initialValue={t("country")}
                    disabled={
                      preview ||
                      (userData.country_id && !id && !userData.is_admin)
                    }
                    required
                  />
                </div>
                <div className="width-50">
                  <SelectWithSearch
                    label={t("city")}
                    name={"city_id"}
                    onchange={(value) => {
                      setFieldValue("city_id", value.value);
                    }}
                    options={cities.map((city) => {
                      return {
                        id: city.id,
                        value: city.id,
                        label:
                          i18n.language === Lang.AR
                            ? city.name_ar
                            : city.name_en,
                      };
                    })}
                    value={{
                      id: values.city_id,
                      value: values.city_id,
                      label:
                        i18n.language === Lang.AR
                          ? cities.filter(
                              (city) => city.id === Number(values.city_id)
                            )[0]?.name_ar
                          : cities.filter(
                              (city) => city.id === Number(values.city_id)
                            )[0]?.name_en,
                    }}
                    error={errors.city_id}
                    initialValue={t("city")}
                    disabled={
                      preview || (userData.city_id && !id && !userData.is_admin)
                    }
                  />
                </div>
                <div className="width-50">
                  <Input
                    type="text"
                    label={t("id_number")}
                    name="id_number"
                    placeholder={t("id_number")}
                    onChange={handleChange}
                    value={values.id_number}
                    error={errors.id_number}
                    readonly={
                      preview ||
                      (userData.id_number && !id && !userData.is_admin)
                    }
                    required
                  />
                </div>
                <div className="width-50">
                  <SelectWithSearch
                    label={t("nationality")}
                    name={"nationality_id"}
                    onchange={(value) => {
                      setFieldValue("nationality_id", value.value);
                    }}
                    options={countries.map((country) => {
                      return {
                        id: country.id,
                        value: country.id,
                        label: `${country.nationality} - ${country.name}`,
                      };
                    })}
                    value={{
                      id: values.nationality_id,
                      value: values.nationality_id,
                      label: (() => {
                        const filteredCountry = countries.find(
                          (country) =>
                            country.id === Number(values.nationality_id)
                        );
                        return filteredCountry
                          ? `${filteredCountry.nationality} ${filteredCountry.name}`
                          : null;
                      })(),
                    }}
                    error={errors.nationality_id}
                    initialValue={t("nationality")}
                    disabled={
                      preview ||
                      (userData.nationality_id && !id && !userData.is_admin)
                    }
                    required
                  />
                </div>
                <div className="width-100">
                  <RadioButton
                    name="isMedical"
                    label={t("editProfile.question")}
                    buttons={[
                      {
                        name: "medical",
                        label: t("editProfile.yes"),
                        onChange: () => {
                          setFieldValue("is_medical", "1");
                          setFieldValue("specialty_id", "");
                          setFieldValue("department_id", "");
                          setFieldValue("scghs", "");
                        },
                        checked: values.is_medical === "1",
                        value: "1",
                      },
                      {
                        name: "notMedical",
                        label: t("editProfile.no"),
                        onChange: () => {
                          setFieldValue("is_medical", "0");
                          setFieldValue("specialty_id", "");
                          setFieldValue("department_id", "");
                          setFieldValue("scghs", "");
                        },
                        checked: values.is_medical === "0",
                        value: "0",
                      },
                    ]}
                    error={errors.is_medical}
                    required
                    readonly={
                      preview
                      // ||
                      // (userData.is_medical && !id && !userData.is_admin)
                    }
                  />
                </div>
                {values.is_medical === "1" && (
                  <>
                    <div className="width-50">
                      <SelectWithSearch
                        label={t("editProfile.medicalSpecialty")}
                        name={"specialty_id"}
                        onchange={(value) => {
                          setFieldValue("specialty_id", value.value);
                          setFieldValue("department_id", "");
                          setDepartments(
                            specialties.filter(
                              (specialty) =>
                                specialty.id === Number(value.value)
                            )[0]?.departments
                          );
                        }}
                        options={specialties.map((specialty) => {
                          return {
                            id: specialty.id,
                            value: specialty.id,
                            label:
                              i18n.language === Lang.AR
                                ? specialty.name_ar
                                : specialty.name_en,
                          };
                        })}
                        value={{
                          id: values.specialty_id || userData.specialty_id,
                          value: values.specialty_id || userData.specialty_id,
                          label:
                            i18n.language === Lang.AR
                              ? specialties.filter(
                                  (specialty) =>
                                    specialty.id ===
                                      Number(values.specialty_id) ||
                                    specialty.id ===
                                      Number(userData.specialty_id)
                                )[0]?.name_ar
                              : specialties.filter(
                                  (specialty) =>
                                    specialty.id ===
                                      Number(values.specialty_id) ||
                                    specialty.id ===
                                      Number(userData.specialty_id)
                                )[0]?.name_en,
                        }}
                        error={errors.specialty_id}
                        initialValue={t("editProfile.medicalSpecialty")}
                        disabled={
                          preview
                          // ||
                          // (userData.specialty_id && !id && !userData.is_admin)
                        }
                        required
                      />
                    </div>
                    <div className="width-50">
                      <SelectWithSearch
                        label={t("editProfile.department")}
                        name={"department_id"}
                        onchange={(value) => {
                          setFieldValue("department_id", value.value);
                        }}
                        options={departments.map((department) => {
                          return {
                            id: department.id,
                            value: department.id,
                            label: department.name,
                          };
                        })}
                        value={{
                          id: values.department_id || userData.department_id,
                          value: values.department_id || userData.department_id,
                          label: departments.filter(
                            (department) =>
                              department.id === Number(values.department_id) ||
                              department.id === Number(userData.department_id)
                          )[0]?.name,
                        }}
                        error={errors.department_id}
                        initialValue={t("editProfile.department")}
                        disabled={
                          preview
                          // ||
                          // (userData.department_id && !id && !userData.is_admin)
                        }
                        required
                      />
                    </div>
                    <div className="width-50">
                      <Input
                        type="text"
                        label={t("editProfile.memoryNumbers")}
                        name="scghs"
                        placeholder={t("editProfile.memoryNumbersLength")}
                        onChange={handleChange}
                        value={values.scghs || userData.scghs}
                        error={errors.scghs}
                        readonly={
                          preview
                          //   ||
                          //  (userData.scghs && !id && !userData.is_admin)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              {!preview && (
                <div className="profile-save-container">
                  <CustomButton
                    value={t("save")}
                    colors={"#036c77"}
                    type="submit"
                    loading={isSubmitting}
                    disable={preview}
                  />
                </div>
              )}
            </form>
          )}
        </Formik>
      </MainBox>
      {!preview && (
        <>
          <BasicModal ref={completeProfileModal}>
            {showCompleteProfileModal && (
              <WarningModal
                img={editProfile}
                description={t("editProfile.complete_profile_msg")}
                successText={t("editProfile.complete_profile")}
                successColor="#036c77"
                action={handleCloseCompleteProfileModal}
              />
            )}
          </BasicModal>
          <BasicModal ref={emailModal}>
            {showEmailModal && (
              <MainBox className="p-0 rounded">
                <div className="email-modal">
                  <div className="email-modal-header">
                    <h2 className="email-modal-title">
                      {t("editProfile.add_email")}
                    </h2>
                    <img
                      src={exit}
                      alt="exit"
                      className="email-modal-exit"
                      onClick={handleCloseEmailModal}
                    />
                  </div>
                  <div className="email-modal-body">
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        setLoading(true);
                        const response =
                          emailModalType === "main"
                            ? updateEmail(values.email)
                            : alternativeEmail(values.email);
                        response
                          .then((res) => {
                            toast.success(
                              <span
                                style={{ fontSize: 13, fontWeight: "bold" }}
                              >
                                {emailModalType === "main"
                                  ? t(
                                      "editProfile.email_confirmation_msg_logout"
                                    )
                                  : t("editProfile.email_confirmation_msg")}
                              </span>
                            );
                            if (userData.is_email_generated === "1") {
                              setTimeout(() => {
                                localStorage.removeItem("token");
                                localStorage.removeItem("name");
                                localStorage.removeItem("email");
                                localStorage.removeItem("is_admin");
                                localStorage.removeItem("roles");
                                localStorage.removeItem("type");
                                localStorage.removeItem("name_ar");
                                localStorage.removeItem("name_en");
                                localStorage.removeItem("img");
                                localStorage.removeItem("phone");
                                localStorage.removeItem("completed_profile");
                                window.location.href = "/";
                              }, 5000);
                            }
                          })
                          .catch((err) => {
                            toast.error(
                              <span
                                style={{ fontSize: 13, fontWeight: "bold" }}
                              >
                                {typeof err === "string" ? (
                                  <span
                                    style={{
                                      fontSize: 12,
                                      fontWeight: "500",
                                      display: "block",
                                    }}
                                  >
                                    {err}
                                  </span>
                                ) : err?.response?.data ? (
                                  <>
                                    <span
                                      style={{
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        display: "block",
                                      }}
                                    >
                                      {err?.response?.data?.msg}
                                    </span>
                                    <ul>
                                      {Object.keys(
                                        err?.response?.data?.errors
                                      ).map((key) => {
                                        return (
                                          <li>
                                            <span style={{ fontSize: 11 }}>
                                              {err?.response?.data?.errors[key]}
                                            </span>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </>
                                ) : (
                                  " Error"
                                )}
                              </span>
                            );
                          })
                          .finally(() => {
                            setSubmitting(false);
                            handleCloseEmailModal();
                          });
                      }}
                      validateOnChange={true}
                      validate={(values) => {
                        const errors = {};
                        if (!values.email) {
                          errors.email = t("crud.errors.required");
                        }
                        if (
                          values.email &&
                          !values.email.match(
                            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
                          )
                        ) {
                          errors.email = t("crud.errors.invalidEmail");
                        }

                        if (
                          values.email === userData.email ||
                          values.email === userData.alternative_email
                        ) {
                          errors.email = t("crud.errors.sameEmail");
                        }

                        return errors;
                      }}
                    >
                      {({ values, errors, handleChange, handleSubmit }) => (
                        <form
                          onSubmit={handleSubmit}
                          className="email-modal-form"
                        >
                          <div className="width-100">
                            <Input
                              type="email"
                              label={t("new_email")}
                              name="email"
                              placeholder={t("new_email")}
                              onChange={handleChange}
                              value={values.email}
                              error={errors.email}
                              required
                              direction="ltr"
                            />
                          </div>
                          <div className="email-modal-button">
                            <div className="email-modal-button-text-container">
                              <span className="email-modal-button-text">
                                {t("editProfile.email_confirmation_msg")}
                              </span>
                              {emailModalType === "main" && (
                                <span className="email-modal-button-text">
                                  {t("editProfile.email_generated_msg_logout")}
                                </span>
                              )}
                            </div>
                            <CustomButton
                              value={t("editProfile.add_email")}
                              colors="#036c77"
                              type="submit"
                              loading={loading}
                              disable={loading}
                            />
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </MainBox>
            )}
          </BasicModal>
        </>
      )}
    </>
  );
}
