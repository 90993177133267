import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./ContentView.module.css";
function AudioView({ question }) {
  const { t } = useTranslation();
  const [linkURL, setLinkURL] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch(question.link, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
        },
      });
      const blob = await response.blob();
      setLinkURL(URL.createObjectURL(blob));
    };

    if (question.link) fetchImage();
  }, [question.link]);

  return (
    <div>
      <div className={classes["exhibition-details__audios-item"]}>
        <div className={classes["exhibition-details__audios-item-title"]}>
          {t("trainer.course.lesson.audio.previewtitle")}
        </div>
        {linkURL && (
          <audio controls>
            <source src={linkURL} />
          </audio>
        )}
      </div>
      <div style={{ paddingTop: 15 }}>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          className={classes["course-slider-item-content-inner-body"]}
        />
      </div>
    </div>
  );
}
export default AudioView;
