import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { decrypt } from "../../../../Helpers/Secret";
import { deleteCoupon, fetchCouponsList } from "../../../../Redux/Actions/coupons/couponsList.action";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { exportCoupons } from "../../../../Services/api/coupon/couponProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import DeleteIcon from "../../../../assets/icons/warning.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CouponsFilter from "./CouponsFilter";
import CouponsTable from "./CouponsTable";
import { filterActions } from "./CouponsTableAction";
import classes from "./coupons.module.css";

const Coupons = ({ type }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { couponsListMeta, deleteCouponLoading, couponsListLoading } = useSelector((state) => state.couponsListReducer);

  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentCouponFilter, setCurrentCouponFilter] = useState({});
  const [filterData, setFilterData] = useState({
    name: "",
    mechanism: "",
    start_date: "",
    end_date: "",
    parent_type: "",
    course: "",
    program: "",
    is_used: "",
    status: "",
    include_tax: "",
    coupon_email: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    ...(type === "sendCoupon" || type === "receivedCoupon"
      ? [
          {
            id: "privateProfile",
            page: t("sidebar.label.private_profile"),
            pagePath: "/private-profile",
          },
          {
            id: `sent_coupon`,
            page: t(`private_profile.${type === "sendCoupon" ? "sent" : "received"}_coupon_gift`),
            pagePath: "#",
            active: true,
          },
        ]
      : [
          {
            id: "exhibition",
            page: t(`coupon.label.${type === "gift" ? "gift_" : ""}coupon`),
            active: true,
          },
        ]),
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      mechanism: "",
      start_date: "",
      end_date: "",
      parent_type: "",
      course: "",
      program: "",
      is_used: "",
      status: "",
      include_tax: "",
      coupon_email: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.title = filterData.name);
    filterData.mechanism && (filter.mechanism = filterData.mechanism);
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);
    filterData.parent_type && (filter.parent_type = filterData.parent_type);
    filterData.is_used && (filter.is_used = filterData.is_used);
    filterData.status && (filter.status = filterData.status);
    filterData.include_tax && (filter.include_tax = filterData.include_tax);
    filterData.coupon_email && (filter.coupon_email = filterData.coupon_email);
    (filterData.course || filterData.program) &&
      (filterData.parent_type == 1 ? (filter.parent_id = filterData.course) : (filter.parent_id = filterData.program));

    type === "notGift" && (filter.not_paid = 1);
    type === "gift" && (filter.is_paid = 1);
    return filter;
  };

  const fetchAllCouponHandler = () => {
    const filter = getFilterData();

    dispatch(
      fetchCouponsList({
        page: currentPage,
        perPage: 10,
        filter,
        type,
      })
    );
    setCurrentCouponFilter(filter);
  };

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { not_in_program: 1, is_program: 0 } : {}),
      mode: "Paid",
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      });
  };

  useEffect(() => {
    getAllCoursesProgramsHandler({
      is_program: false,
    });
    getAllCoursesProgramsHandler({
      is_program: true,
    });
  }, []);
  useEffect(() => {
    fetchAllCouponHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const exportHandler = async (export_type) => {
    setIsExportLoading(true);
    try {
      await exportCoupons(export_type, currentCouponFilter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const deleteHandler = (id) => {
    setShowDeleteModal(true);
    setCouponId(id);
  };

  const closeDeleteModalHandler = () => {
    setShowDeleteModal(false);
  };

  const confirmDeleteCouponHandler = () => {
    if (!couponId) return;
    dispatch(deleteCoupon(couponId, closeDeleteModalHandler));
  };

  const refetchSurveys = async (params = getFilterData()) => {
    try {
      let customFilter = { ...params, page: currentPage };
      await fetchAllCouponHandler(customFilter);
    } catch (e) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  useEffect(() => {
    refetchSurveys();
  }, [currentPage]);

  return (
    <div className={classes["coupon-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      {couponsListLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["coupon-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t(
                    `${
                      type === "sendCoupon"
                        ? "private_profile.sent_coupon_gift"
                        : type === "receivedCoupon"
                        ? "private_profile.received_coupon_gift"
                        : `coupon.label.${type === "gift" ? "gift_" : ""}coupon`
                    }`
                  )}
                  actions={filterActions({
                    [type === "receivedCoupon" ? "" : "showFilter"]: showFilterHandler,
                    [type === "notGift" ? "addAction" : ""]: () => {
                      history.push(
                        `${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/coupon/add`
                      );
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <CouponsFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={fetchAllCouponHandler}
                    resetFilterHandler={resetFilterHandler}
                    courses={courses}
                    programs={programs}
                    type={type}
                  />
                )}

                <CouponsTable deleteHandler={deleteHandler} type={type} />
                <Pagination
                  count={couponsListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
              {showDeleteModal && (
                <SharedModal
                  icon={DeleteIcon}
                  title={t("coupon.label.delete_aprove")}
                  confirmMsg={t("modal.coupon.delete.body")}
                  confirmText={t("general.confirm")}
                  cancelText={t("general.cancel")}
                  onCancel={() => {
                    setShowDeleteModal(false);
                  }}
                  onConfirm={() => {
                    confirmDeleteCouponHandler();
                  }}
                  isLoading={deleteCouponLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupons;
