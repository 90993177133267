import { useEffect, useRef } from "react";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/youtube.svg";

export default function H5pQuestionView({ question, setFieldValue }) {
  const iframeRef = useRef(null);
  // const [linkURL, setLinkURL] = useState("");

  function saveRes(event) {
    if (!process.env.REACT_APP_SERVER_PATH.startsWith(event.origin)) {
      return;
    }
    if (event.data.statement?.result) {
      setFieldValue("answer", event.data.statement.result.score.scaled);
    }
  }

  useEffect(() => {
    if (question.media?.path) {
      window.addEventListener("message", saveRes);
      return () => window.removeEventListener("message", saveRes);
    }
  }, []);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(question.link, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token") ?? localStorage.getItem("guest_token")}`,
  //       },
  //     });
  //     const blob = await response.blob();
  //     setLinkURL(URL.createObjectURL(blob));
  //   };

  //   if (question.link) fetchImage();
  // }, [question.link]);

  return (
    <>
      {question.link ? (
        <iframe
          ref={iframeRef}
          title={"H5P"}
          id={"iframeH5PQuestion" + question.id}
          src={`${process.env.REACT_APP_SERVER_PATH}h5p/player?path=${question.link}`}
          key={question.id}
          width={"100%"}
          height={"644px"}
          allow="fullscreen"
        ></iframe>
      ) : (
        <button
          type="button"
          disabled={!question.link}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon
            className={`tw-w-16 tw-h-16 ${question.link ? "tw-stroke-teal-600" : "tw-stroke-gray-400"}`}
          />
        </button>
      )}
    </>
  );
}
