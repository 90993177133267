import { useTranslation } from "react-i18next";
import ManImage from "../../../../assets/image/young-man-student-with-notebooks-showing-thumb-up-approval-smiling-satisfied-blue-studio-background-removebg-preview.png";
import classes from "./homeFooter.module.css";

const HomeFooter = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.footerSection}>
      <div className={`${classes.footer} container-fluid`}>
        <div className={classes.footerContent}>
          <h2>{t("home.footerHeading")}</h2>
          <p>{t("home.footerSubHeading")}</p>
        </div>
        <div className={classes.footerImage}>
          <img src={ManImage} alt="ManImage" />
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
