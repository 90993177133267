import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { decrypt } from "../../Helpers/Secret";
import { boothActions } from "../../Redux/Actions/Booth/booth.actions";
import {
  DOC_PDF,
  JPEG_TYPE,
  JPG_TYPE,
  MP3_TYPE,
  PNG_TYPE,
  acceptImages,
  fromKbToMb,
  validateAudio,
  validateDocument,
  validateImage,
} from "../../Services/Validator";
import { getBooth } from "../../Services/api/Exhibition/ExhibitionProvider";
import { getListType } from "../../Services/api/TypeFile/TypeFileProvider";
import HomeIcon from "../../assets/icons/home.svg";
import Breadcrumb from "../Shared/Components/Breadcrumb/Breadcrumb";
import Button from "../Shared/Components/Button/HButton/Button";
import RowTabs from "../Shared/Components/ExhibitionBooth/RowTabs";
import Input from "../Shared/Components/Input/Input";
import InputFile from "../Shared/Components/InputFile/InputFile";
import Select from "../Shared/Components/Select/Select";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";
import { BasicInformation } from "./BasicInformation/BasicInformation";
import ReviewAndCreation from "./ReviewAndCreation/ReviewAndCreation";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
  },
  alertOctagon: {
    marginBottom: 38,
    width: 80,
    height: 80,
  },
  confirmPaymentTxt: {
    textAlign: "center",
    fontSize: 24,
    color: "#555555",
    fontWeight: 400,
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    justifyContent: "space-between",
  },
  yesBtn: {
    backgroundColor: "#24B3B9",
    color: "#ffffff",
    borderRadius: 6,
    borderColor: "#EBEBEB",
    width: 150,
    height: 50,
  },
  noBtn: {
    backgroundColor: "#CFCFCF",
    color: "#858585",
    borderRadius: 6,
    borderColor: "#EBEBEB",
    width: 150,
    height: 50,
  },
  btnBack: {
    webkitAppearance: "button",
    backgroundColor: "transparent",
    backgroundImage: "none",
  },
}));

const RenderBannerCards = ({ ExhibitionNum, position, positions, myPosition, disable = false, setFieldValue }) => {
  const positionsIndex = positions.map((item) => item.index);
  const positionsStatus = positions.map((item) =>
    item.sponsor_status === 2 && item.payment_status === 2 ? "approved" : "not-approved"
  );

  const handleItemClick = (index) => {
    if (disable) return;
    if (positionsIndex.includes(index) && index !== myPosition) return;
    setFieldValue("position", index);
  };

  const dataArray = Array.from({ length: ExhibitionNum }, (_, index) => ExhibitionNum - index);

  return (
    <>
      <div className="row position-box booth_cards">
        {dataArray.map((item, cardIndex) => {
          const originalIndex = ExhibitionNum - cardIndex;
          return (
            <div
              key={cardIndex}
              className={`${position === originalIndex ? "active-position" : ""} 
                  ${
                    positionsIndex.includes(originalIndex) && myPosition !== originalIndex
                      ? positionsStatus[positionsIndex.indexOf(originalIndex)] === "approved"
                        ? "approved-position"
                        : "not-approved-position"
                      : ""
                  } position-ui`}
              style={{ cursor: disable ? "not-allowed" : "pointer" }}
              onClick={() => handleItemClick(originalIndex)}
            >
              {item}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default function Booth() {
  require("./exhibition.css");
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [exhibition_id, booth_id] = id.split("_");
  const roles = JSON.parse(decrypt(localStorage.getItem("roles")) || "[]");
  const dispatch = useDispatch();
  const { loading, createBoothLoading, updateBoothDraftLoading, exhibition, positions, prices } = useSelector(
    (state) => state.boothReducer
  );

  const [filesDoc, setFilesDoc] = useState([0]);
  const [Tabs, setTabs] = useState(1);
  const [editBooth, setEditBooth] = useState(false);
  const [boothData, setBoothData] = useState(null);
  const [boothErrors, setBoothErrors] = useState({});
  const [loadingBooth, setLoadingBooth] = useState(true);
  const [ExhibitionData, setExhibitionData] = useState(null);
  const [Banner, setBanner] = useState([0, 1, 2]);
  const [errorBanner, setErrorBanner] = useState([]);

  const [boothSize, setBoothSize] = useState("small");
  const [boothPrice, setBoothPrice] = useState("");

  const boothOptions = [
    {
      value: "small",
      id: "small",
      label: t("booth.small"),
    },
    {
      value: "medium",
      id: "medium",
      label: t("booth.medium"),
    },
    {
      value: "larg",
      id: "large",
      label: t("booth.large"),
    },
  ];

  useEffect(() => {
    if (roles.includes("admin") === false && roles.includes("sponser") === false) {
      history.push(`/Exhibition`);
    }
  }, []);

  useEffect(() => {
    if (booth_id) {
      _getBooth(booth_id);
      setEditBooth(true);
    } else {
      setLoadingBooth(false);
    }
  }, [booth_id]);

  useEffect(() => {
    dispatch(boothActions.fetchExhibitionPositions(exhibition_id));
  }, [exhibition_id]);

  const setBannerBasedOnBoothSize = (size) => {
    setBanner(
      size === "small" ? [0, 1, 2] : size === "medium" ? [0, 1, 2, 3] : size === "larg" ? [0, 1, 2, 3, 4, 5] : []
    );
  };

  useEffect(() => {
    if (exhibition) {
      setExhibitionData(exhibition);
      setBoothPrice({
        value: exhibition?.small_price === ".00" ? 0 : exhibition?.small_price,
        name: "small",
      });
    }
  }, [exhibition]);

  const _getBooth = async (id) => {
    try {
      const res = await getBooth(id);
      if (res.status === 200) {
        setBoothData(res.data.booth);

        let Banners_count = res.data.booth.banners.length;
        let Products_count = res.data.booth.products.length;
        let ProductArr = [...Array(Products_count).keys()];

        if (Banners_count === 3) {
          setBanner([0, 1, 2]);
        } else if (Banners_count === 4) {
          setBanner([0, 1, 2, 3]);
        } else if (Banners_count === 6) {
          setBanner([0, 1, 2, 3, 4, 5]);
        }
        setFilesDoc(ProductArr);

        setLoadingBooth(false);
      }
    } catch (error) {
      setLoadingBooth(false);
    }
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "exhibition",
      page: ExhibitionData?.name,
      pagePath: "/Exhibition",
    },
    {
      id: "exhibition",
      page: booth_id ? t("booth.edit") : t("Exhibition.book_a_corner"),
      active: true,
    },
  ];

  const [listTypeFile, setListTypeFile] = useState([]);

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          resolve();
        },
        (error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("programs.failed_loading")}</span>);
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const getTypeFile = (searchType) => {
    return listTypeFile.filter((file) => file.type === searchType);
  };

  const getData = (type, data) => {
    if (!data) return null;

    switch (type) {
      case "file":
      case "document":
        return { preview: data };
      case "products":
        return data.map((pro) => ({
          name: pro?.name,
          description: pro?.description_html,
          image: { preview: pro?.image },
          url_title: pro?.url_title,
          url: pro?.url,
        }));
      case "banners":
        return data.map((ban) => ({ image: { preview: ban?.image } }));
      case "webinars":
        return data.map((web) => ({ name: web?.name, link: web?.link }));
      default:
        return null;
    }
  };

  const validateFile = (file, type) => {
    if (!file) return false;
    if (type === "image" || type === "gallery") {
      return validateImage(file, getTypeFile(PNG_TYPE)[0]?.max_size);
    } else if (type === "audio") {
      return validateAudio(file, getTypeFile(MP3_TYPE)[0]?.max_size);
    } else if (type === "document") {
      return validateDocument(file, getTypeFile(DOC_PDF)[0]?.max_size);
    }
  };

  const validateUrl = (url) => {
    if (!url) return false;
    const urlRegex = new RegExp("^(http|https)://", "i");
    return urlRegex.test(url);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="py-5">
            <Breadcrumb list={breadcrumbList} />
          </div>

          <div className="container-fluid">
            <RowTabs tabs={Tabs} editBooth={editBooth} />
          </div>
        </div>
      </div>

      <div className="container-fluid profile_wrapper">
        <div className="row">
          <div className="col-12">
            <div className={loading ? null : "table_bank_tran"}>
              {loading || loadingBooth ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <div className="admin_input_sponsor">
                  <Formik
                    initialValues={{
                      name: boothData?.name || "",
                      description: boothData?.description || "",
                      exhibition_id: exhibition_id,
                      logo: boothData?.logo ? getData("file", boothData?.logo) : "",
                      audio: boothData?.audio ? getData("file", boothData?.audio) : "",
                      document: boothData?.document ? getData("document", boothData?.document) : "",
                      video: "",
                      products: boothData?.products
                        ? getData("products", boothData?.products)
                        : [
                            {
                              name: "",
                              description: "",
                              image: "",
                              url_title: "",
                              url: "",
                            },
                          ],
                      banners: boothData?.banners
                        ? getData("banners", boothData?.banners)
                        : Banner.map((item) => ({
                            image: "",
                          })),
                      booth_main_img: boothData?.booth_main_img ? getData("file", boothData?.booth_main_img) : "",
                      payment_proof_img_name: "",
                      account_holder_name: boothData?.sponsor?.name || "",
                      phone_number: boothData?.sponsor?.phone || "",
                      bank_name: boothData?.cartItem?.cart?.order?.bank_name || "",
                      iban_number: boothData?.cartItem?.cart.order?.iban_number || "",
                      cart_id: boothData?.cartItem?.cart_id || "",
                      webinars: boothData?.webinars ? getData("webinars", boothData?.webinars) : [],
                      filesError: {},
                      position: boothData?.position || "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      const getPreviewPath = (item) => (item?.preview ? item.preview.split("exhibitions/")[1] : item);

                      let dataToBeUploaded = {
                        name: values.name,
                        description: values.description,
                        exhibition_id,
                        position: values.position,
                        size: boothSize,
                        price:
                          boothSize === "small"
                            ? prices.booth_small_price
                            : boothSize === "medium"
                            ? prices.booth_medium_price
                            : prices.booth_large_price,
                        participation_type: "booth",
                        logo: getPreviewPath(values.logo),
                        audio: getPreviewPath(values.audio),
                        document: getPreviewPath(values.document),
                        booth_main_img: getPreviewPath(values.booth_main_img),
                      };

                      if (booth_id && roles.includes("sponser")) {
                        dataToBeUploaded.booth_products = values.products.map((item, index) => ({
                          ...item,
                          image: getPreviewPath(item.image),
                        }));
                        dataToBeUploaded.booth_banners = values.banners.map((item, index) => ({
                          ...item,
                          image: getPreviewPath(item.image),
                          position: index + 1,
                        }));
                        dataToBeUploaded.booth_webinars = values.webinars.map((item) => ({
                          name: item.name,
                          link: item.link,
                        }));

                        dataToBeUploaded.exhibition_booth_id = booth_id;
                        dispatch(boothActions.updateBoothDraft(dataToBeUploaded, t, history));
                      } else {
                        dataToBeUploaded.products = values.products.map((item, index) => ({
                          ...item,
                          image: getPreviewPath(item.image),
                        }));
                        dataToBeUploaded.banners = values.banners.map((item, index) => ({
                          ...item,
                          image: getPreviewPath(item.image),
                          position: index + 1,
                        }));
                        dataToBeUploaded.webinars = values.webinars;

                        if (roles.includes("admin")) {
                          dataToBeUploaded.exhibition_booth_id = booth_id;
                          dispatch(boothActions.updateBooth(dataToBeUploaded, booth_id, exhibition_id, history));
                        } else {
                          dispatch(boothActions.createBooth(dataToBeUploaded, history));
                        }
                      }
                    }}
                    validateOnChange={true}
                    validate={(values) => {
                      const errors = {};

                      for (let i = 0; i < Banner.length; i++) {
                        values[`banners_position_${i}`] = i + 1;
                      }

                      if (Tabs === 1) {
                        if (!values.name) {
                          errors.name = t("crud.errors.required");
                        }

                        if (!values.booth_main_img) {
                          errors.booth_main_img = t("crud.errors.required");
                        }

                        if (values.filesError["booth_main_img"]?.error) {
                          errors.booth_main_img =
                            t("booth.Note_image_wronge_type") +
                            " PNG " +
                            t("booth.Note_image_large_size") +
                            fromKbToMb(
                              Math.max(
                                getTypeFile(PNG_TYPE)[0]?.max_size,
                                getTypeFile(JPEG_TYPE)[0]?.max_size,
                                getTypeFile(JPG_TYPE)[0]?.max_size
                              )
                            ) +
                            t("booth.megabyte");
                        }

                        if (!values.logo) {
                          errors.logo = t("crud.errors.required");
                        }

                        if (values.filesError["logo"]?.error) {
                          errors.logo =
                            t("booth.Note_image_wronge_type") +
                            " PNG " +
                            t("booth.Note_image_large_size") +
                            fromKbToMb(
                              Math.max(
                                getTypeFile(PNG_TYPE)[0]?.max_size,
                                getTypeFile(JPEG_TYPE)[0]?.max_size,
                                getTypeFile(JPG_TYPE)[0]?.max_size
                              )
                            ) +
                            t("booth.megabyte");
                        }

                        if (!values.audio) {
                          errors.audio = t("crud.errors.required");
                        }

                        if (values.filesError["audio"]?.error) {
                          errors.audio = `${t("Exhibition.validation_MP3")}, ${t(
                            "Exhibition.validation_MP3_size"
                          )} ${fromKbToMb(getTypeFile(MP3_TYPE)[0]?.max_size)} ${t("booth.megabyte")}`;
                        }

                        if (!values.document) {
                          errors.document = t("crud.errors.required");
                        }

                        if (values.filesError["document"]?.error) {
                          errors.document = `${t("booth.Note_file_wronge_type")} PDF ${t(
                            "booth.Note_file_large_size"
                          )} ${fromKbToMb(getTypeFile(DOC_PDF)[0]?.max_size)} ${t("booth.megabyte")}`;
                        }

                        if (!values.webinars || !values.webinars.length) {
                          errors.webinars = t("crud.errors.required");
                        }

                        // the link of webinar is should be url start with https:// or http://

                        if (values.webinars.length > 0) {
                          values.webinars.forEach((item, index) => {
                            if (!item.name) {
                              errors[`webinars[${index}][name]`] = t("crud.errors.required");
                            }

                            if (!item.link) {
                              errors[`webinars[${index}][link]`] = t("crud.errors.required");
                            }

                            if (item.link && !validateUrl(item.link)) {
                              errors.webinars = t("booth.validation_link");
                            }
                          });
                        }
                      }

                      if (Tabs === 2) {
                        if (!values.position) {
                          errors.position = t("crud.errors.required");
                        }

                        if (values.banners.length > 0) {
                          setErrorBanner([]);
                          values.banners.forEach((item, index) => {
                            if (!item.image || !item.image === "") {
                              if (
                                values.filesError["banners"]?.index === index &&
                                values.filesError["banners"]?.error
                              ) {
                                setErrorBanner((prev) => [
                                  ...prev,
                                  t("booth.Note_image_wronge_type") +
                                    " PNG " +
                                    t("booth.Note_image_large_size") +
                                    fromKbToMb(
                                      Math.max(
                                        getTypeFile(PNG_TYPE)[0]?.max_size,
                                        getTypeFile(JPEG_TYPE)[0]?.max_size,
                                        getTypeFile(JPG_TYPE)[0]?.max_size
                                      )
                                    ) +
                                    t("booth.megabyte"),
                                ]);
                              } else {
                                setErrorBanner((prev) => [...prev, t("crud.errors.required")]);
                              }
                            } else {
                              setErrorBanner((prev) => [...prev, ""]);
                            }
                          });
                        }
                      }

                      if (Tabs === 3) {
                        for (let i = 0; i < values.products.length; i++) {
                          if (!values.products[i].name) {
                            errors[`products[${i}][name]`] = t("crud.errors.required");
                          }

                          if (!values.products[i].url_title) {
                            errors[`products[${i}][url_title]`] = t("crud.errors.required");
                          }

                          if (!values.products[i].description) {
                            errors[`products[${i}][description]`] = t("crud.errors.required");
                          }

                          if (!values.products[i].image) {
                            errors[`products[${i}][image]`] = t("crud.errors.required");
                          }

                          if (values.filesError["products"]?.index === i && values.filesError["products"]?.error) {
                            errors[`products[${i}][image]`] =
                              t("booth.Note_image_wronge_type") +
                              " PNG " +
                              t("booth.Note_image_large_size") +
                              fromKbToMb(
                                Math.max(
                                  getTypeFile(PNG_TYPE)[0]?.max_size,
                                  getTypeFile(JPEG_TYPE)[0]?.max_size,
                                  getTypeFile(JPG_TYPE)[0]?.max_size
                                )
                              ) +
                              t("booth.megabyte");
                          }

                          if (!values.products[i].url) {
                            errors[`products[${i}][url]`] = t("crud.errors.required");
                          }
                        }
                      }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form onSubmit={handleSubmit} className="admin_add_form">
                        <div className="row">
                          <div className="col-md-12" style={{ paddingBottom: "45px" }}>
                            <h3 className="booth-header-txt-h3">{t("Exhibition.book_a_corner")} </h3>
                            <span className="booth-header-txt-span">
                              {Tabs === 1
                                ? t("booth.basic_info")
                                : Tabs === 2
                                ? t("booth.design_booth")
                                : Tabs === 3
                                ? t("booth.add_product")
                                : Tabs === 4
                                ? t("booth.review_creation")
                                : Tabs === 5 && t("booth.payment")}
                            </span>
                          </div>
                          <div className={`col-md-12 p-0 row ${Tabs !== 1 ? " hide" : " "}`}>
                            <BasicInformation
                              handleChange={handleChange}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              values={values}
                              getTypeFile={getTypeFile}
                              validateFile={validateFile}
                            />
                          </div>
                          {/* Tab2 page */}
                          <div
                            className={`col-md-12 p-0 ${Tabs !== 2 ? " hide" : " "}`}
                            style={{ paddingBottom: "41px" }}
                          >
                            <div className="col-md-12" style={{ marginBottom: 8 }}>
                              <Select
                                label={t("booth.size")}
                                name="size"
                                onchange={(event) => {
                                  setBoothPrice({
                                    value:
                                      ExhibitionData[
                                        `${event.target.value === "larg" ? "large" : event.target.value}_price`
                                      ] === ".00"
                                        ? 0
                                        : ExhibitionData[
                                            `${event.target.value === "larg" ? "large" : event.target.value}_price`
                                          ],
                                    name: event.target.value,
                                  });
                                  setBoothSize(event.target.value);
                                  setBannerBasedOnBoothSize(event.target.value);
                                  const bannersNumber =
                                    event.target.value === "small" ? 3 : event.target.value === "medium" ? 4 : 6;
                                  const banners = [];
                                  for (let i = 0; i < bannersNumber; i++) {
                                    if (i <= bannersNumber) {
                                      if (values?.banners[i]) {
                                        banners.push(values?.banners[i]);
                                      } else {
                                        banners.push({
                                          image: "",
                                        });
                                      }
                                    } else {
                                      banners.push({
                                        image: "",
                                      });
                                    }
                                  }
                                  setFieldValue("banners", banners);
                                }}
                                options={boothOptions}
                                value={boothSize}
                                initialValue={t("booth.small")}
                                error={errors.size}
                                required={true}
                                disabled={booth_id ? true : false}
                              />
                            </div>
                            <div className="col-md-12" style={{ marginBottom: 31 }}>
                              {boothPrice && (
                                <p className="booth-header-txt-p">
                                  {t(`booth.${boothPrice.name}_price`)}{" "}
                                  {`${
                                    boothPrice.value === 0
                                      ? t("Exhibition.free")
                                      : boothPrice.value + " " + t("Exhibition.rs")
                                  }`}
                                </p>
                              )}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className={classes["input-file__label"]}>{t("booth.corner_banners")}</label>
                              {Banner.map((data, key) => {
                                return (
                                  <div className="row" key={data}>
                                    <div key={data} className="col-md-12" style={{ padding: "10px 0" }}>
                                      <InputFile
                                        id={`banners[${key}]`}
                                        name={`banners[${key}[image]]`}
                                        type="image"
                                        documentWithoutTitle={true}
                                        accept={acceptImages()}
                                        placeholder={t("Exhibition.drag_file")}
                                        className="input-upload-audio"
                                        setFieldValue={setFieldValue}
                                        error={errorBanner[key]}
                                        validate={validateFile}
                                        value={values?.banners[key]?.image}
                                        uploadDirectly={true}
                                        note={`${t("Exhibition.png_note")} ${fromKbToMb(
                                          Math.max(
                                            getTypeFile(PNG_TYPE)[0]?.max_size,
                                            getTypeFile(JPEG_TYPE)[0]?.max_size,
                                            getTypeFile(JPG_TYPE)[0]?.max_size
                                          )
                                        )} ${t("booth.megabyte")}`}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            {/* End Banner */}
                            <div className="col-md-12" style={{ marginTop: 40 }}>
                              <label htmlFor="name" className="admin_add_label">
                                {t("booth.count_booth")}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              {/* ExhibitionNum will get from the api */}
                              {ExhibitionData && (
                                <RenderBannerCards
                                  ExhibitionNum={ExhibitionData?.booths_no}
                                  position={values?.position}
                                  positions={positions}
                                  myPosition={boothData?.position}
                                  disable={booth_id ? true : false}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                              <div className="position-nots">
                                <span className="unavailable-not-completed" /> {t("booth.notCompleted")}
                                <span className="unavailable" /> {t("booth.unAvailable")}
                                <span className="available" /> {t("booth.Available")}
                              </div>

                              <p className={"form-input-error-space"}>{errors.position ? errors.position : null}</p>
                            </div>
                          </div>

                          {/* Tab3 Page */}
                          <div className={`col-md-12 p-0 ${Tabs !== 3 ? " hide" : " "}`}>
                            {filesDoc.length > 0 &&
                              filesDoc.map((doc, index) => {
                                return (
                                  <div key={index} style={{ padding: "20px 0 0 0" }} className="col-md-12">
                                    <div>
                                      <div className="row">
                                        <div
                                          className="col-md-12"
                                          style={{
                                            marginBottom: "30px",
                                            padding: 0,
                                          }}
                                        >
                                          <Input
                                            name={`products[${index}][name]`}
                                            label={t("booth.product_name")}
                                            placeholder={t("booth.product_name")}
                                            type="text"
                                            onChange={handleChange}
                                            required={true}
                                            value={values?.products[index]?.name}
                                          />
                                          {errors[`products[${index}][name]`] && (
                                            <p className={"form-input-error-space"}>
                                              {errors[`products[${index}][name]`]
                                                ? errors[`products[${index}][name]`]
                                                : null}
                                            </p>
                                          )}
                                        </div>
                                        <div className="col-md-12" style={{ marginBottom: 42, padding: 0 }}>
                                          <InputFile
                                            name={`products[${index}][image]`}
                                            type="gallery"
                                            documentWithoutTitle={true}
                                            accept={acceptImages()}
                                            label={t("booth.Product_image")}
                                            maxSize={`${t("Exhibition.upload_max_size")} ${fromKbToMb(
                                              Math.max(
                                                getTypeFile(PNG_TYPE)[0]?.max_size,
                                                getTypeFile(JPEG_TYPE)[0]?.max_size,
                                                getTypeFile(JPG_TYPE)[0]?.max_size
                                              )
                                            )} ${t("booth.megabyte")}`}
                                            setFieldValue={setFieldValue}
                                            error={errors?.imageProduct}
                                            validate={validateFile}
                                            required={true}
                                            value={values?.products[index]?.image}
                                            uploadDirectly={true}
                                            note={`${t("Exhibition.png_note")} ${fromKbToMb(
                                              Math.max(
                                                getTypeFile(PNG_TYPE)[0]?.max_size,
                                                getTypeFile(JPEG_TYPE)[0]?.max_size,
                                                getTypeFile(JPG_TYPE)[0]?.max_size
                                              )
                                            )} ${t("booth.megabyte")}`}
                                          />
                                          {errors[`products[${index}][image]`] && (
                                            <p className={"form-input-error-space"}>
                                              {errors[`products[${index}][image]`]
                                                ? errors[`products[${index}][image]`]
                                                : null}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12" style={{ marginBottom: "30px", padding: 0 }}>
                                      <div className="row">
                                        <div className="col-md-4" style={{ padding: "0 0 0 10px" }}>
                                          <Input
                                            name={`products[${index}][url_title]`}
                                            label={t("booth.title_name")}
                                            placeholder={t("Exhibition.drag_file")}
                                            type="text"
                                            onChange={handleChange}
                                            required={true}
                                            value={values?.products[index]?.url_title}
                                          />
                                          {errors[`products[${index}][url_title]`] && (
                                            <p className={"form-input-error-space"}>
                                              {errors[`products[${index}][url_title]`]
                                                ? errors[`products[${index}][url_title]`]
                                                : null}
                                            </p>
                                          )}
                                        </div>
                                        <div className="col-md-4" style={{ padding: "0 0 0 10px" }}>
                                          <Input
                                            name={`products[${index}][url]`}
                                            label={t("booth.url_page")}
                                            placeholder={t("Exhibition.drag_file")}
                                            type="text"
                                            onChange={handleChange}
                                            required={true}
                                            value={values?.products[index]?.url}
                                          />
                                          {errors[`products[${index}][url]`] && (
                                            <p className={"form-input-error-space"}>
                                              {errors[`products[${index}][url]`]
                                                ? errors[`products[${index}][url]`]
                                                : null}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12" style={{ marginBottom: "30px", padding: 0 }}>
                                      <label>{t("booth.desc_product")}</label>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        onReady={(editor) => {}}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setFieldValue(`products[${index}][description]`, data);
                                        }}
                                        required={true}
                                        onBlur={(event, editor) => {}}
                                        onFocus={(event, editor) => {}}
                                        data={values?.products[index]?.description}
                                      />
                                      <p className={"form-input-error-space"}>
                                        {errors[`products[${index}][description]`]
                                          ? errors[`products[${index}][description]`]
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}

                            <div className="col-md-12 d-flex justify-content-between mb-3 add-product-action">
                              <div className="dashes-line"></div>
                              <button
                                style={{
                                  color: "#126e77",
                                  background: "transparent",
                                  border: "none",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                type="button"
                                onClick={() => {
                                  setFilesDoc((old) => [
                                    ...old,
                                    `${old.length}-${Math.floor(Math.random() * 10000000)}`,
                                  ]);
                                }}
                              >
                                <AddIcon
                                  sx={{
                                    color: "#24B3B9",
                                    border: "1px solid #24B3B9",
                                    marginInlineEnd: 3,
                                  }}
                                />
                                <span>{t("booth.add_pro")}</span>
                              </button>
                              <div className="dashes-line"></div>
                            </div>
                          </div>

                          {/* TAb4 */}
                          <div className={`col-md-12 p-0 ${Tabs !== 4 ? " hide" : " "}`}>
                            <ReviewAndCreation value={values} boothSize={boothSize} boothPosition={values?.position} />
                          </div>
                          {/* Tab5 */}
                          {/* {!editBooth ? (
                            <div className={`col-md-12 p-0 ${Tabs !== 5 ? " hide" : " "}`}>
                              <PaymentWay
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                selectedBox={selectedBox}
                                handleSelectPaymentClick={handleSelectPaymentClick}
                                getTypeFile={getTypeFile}
                                totalPrice={boothData?.price}
                              />
                            </div>
                          ) : null} */}

                          <div
                            className={`col-md-12 d-flex tw-items-center ${
                              Tabs === 1 || Tabs === 5 ? "justify-content-end" : "justify-content-between"
                            } `}
                            style={{ marginTop: 40, gap: "1rem" }}
                          >
                            {Tabs !== 1 && Tabs !== 5 ? (
                              <button
                                className={classes["btnBack"]}
                                type="button"
                                style={{
                                  height: 65,
                                  padding: "0 20px",
                                  borderRadius: "6px",
                                  display: "inline-block",
                                  border: "3px solid #24B3B9",
                                }}
                                onClick={() => {
                                  setTabs(Tabs - 1);
                                }}
                              >
                                <ArrowForwardIcon sx={{ color: "#24B3B9" }} />
                              </button>
                            ) : null}

                            {Tabs === 5 ? (
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                style={{
                                  width: 376,
                                  height: 75,
                                  padding: "4px",
                                  borderRadius: "6px",
                                  display: "inline-block",
                                  backgroundColor: "#046c77 ",
                                  color: "#fff",
                                }}
                                className="admin_add_button btn_zoom"
                              >
                                {t("booth.send")}
                              </button>
                            ) : null}
                            {Tabs === 4 ? (
                              <Button
                                // text={exhibition?.mode === 'Free' ? t("booth.create_booth_btn") : t("booth.create_booth_done_payment")}
                                type="submit"
                                text={booth_id ? t("booth.save_edit_booth") : t("booth.create_booth_btn")}
                                disabled={createBoothLoading || updateBoothDraftLoading}
                                isLoading={createBoothLoading || updateBoothDraftLoading}
                                width={376}
                                height={75}
                                borderRadius={6}
                                style={{ display: "inline", backgroundColor: "#046c77 ", color: "#fff" }}
                                // onClick={() => handleSubmitBooth(values)}
                              />
                            ) : null}
                            {Tabs !== 4 ? (
                              <button
                                type="button"
                                style={{
                                  width: 376,
                                  height: 75,
                                  padding: "4px",
                                  borderRadius: "6px",
                                  display: "inline-block",
                                  backgroundColor: "#046c77 ",
                                  color: "#fff",
                                }}
                                className="admin_add_button btn_zoom"
                                onClick={async () => {
                                  validateForm(values).then((errors) => {
                                    const bannerErrors = [];
                                    if (Tabs === 2) {
                                      for (let i = 0; i < Banner.length; i++) {
                                        if (!values?.banners[i]?.image) {
                                          bannerErrors.push(t("crud.errors.required"));
                                        }
                                      }
                                    }
                                    if (Object.keys(errors).length || bannerErrors.length) {
                                      var el = document.getElementById(Object.keys(errors)[0]);
                                      if (el) {
                                        el.scrollIntoView({
                                          behavior: "smooth",
                                          block: "center",
                                        });
                                        window.scrollTo({
                                          top: 0,
                                          behavior: "smooth",
                                        });
                                      } else
                                        window.scrollTo({
                                          top: 0,
                                          behavior: "smooth",
                                        });
                                    } else {
                                      setTabs(Tabs + 1);
                                      window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                      });
                                    }
                                  });
                                }}
                              >
                                {t("booth.next")}
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
              {/* <div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={open}>
                    <Box className={classes.modalContainer}>
                      <img className={classes.alertOctagon} src={alertOctagon} alt="alertOctagon" />
                      <p className={classes.confirmPaymentTxt}>
                        {t("Exhibition.popupwalletlabel")}
                        <br />
                        {t("Exhibition.popupwalletdescription")}
                      </p>
                      <div className={classes.buttonsRow}>
                        <button type="button" className={classes.yesBtn} onClick={() => setIsPaidByWallet(true)}>
                          {t("Exhibition.popupwalletyes")}
                        </button>
                        <button
                          type="button"
                          className={classes.noBtn}
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          {t("Exhibition.popupwalletno")}
                        </button>
                      </div>
                    </Box>
                  </Fade>
                </Modal>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
